import { StoreEn, StoreObject, StoresDict } from "../types/types";

class OnlineStoresMapper {
  static onlineStoresDict: StoresDict = {
    1: "victory",
    2: "mahsane",
    3: "bitan",
    // 4: "mega",
    5: "carrefour",
    6: "rami",
    7: "shufersal",
    8: "quick",
  };

  static getOnlineStoreIdFromName = (onlineStore: StoreEn) => {
    for (const [id, store] of Object.entries(this.onlineStoresDict)) {
      if (store === onlineStore) {
        return parseInt(id);
      }
    }
    return 0;
  };

  static convertOnlineStoreToObj = (store: StoreEn): StoreObject => {
    return { name: store, id: this.getOnlineStoreIdFromName(store) };
  };

  static convertOnlineStoresToArrOfObj = (otherStores: StoreEn[]): StoreObject[] => {
    return otherStores.map(this.convertOnlineStoreToObj);
  };
}

export default OnlineStoresMapper;
