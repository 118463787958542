import { useRef, useState, RefObject } from "react";
import { Button, Flex } from "../UI";
import "./Contact.css";
import serverAPI from "../../api/server";
import { parseCookies } from "../../utils/utils";
import { dictAuth } from "../Authentication/dictionary";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import Logo from "../Logo/Logo";
import { CookieName } from "../../types/types";

const dict = {
  english: {
    h1: "Contact Us",
    h2: "Help us to help you",
    btn: "Submit",
    wait: "We already got your message, Please await a while to contact us again.",
  },
  hebrew: {
    h1: "צור קשר",
    h2: "סייעו לנו להשתפר",
    btn: "שלח",
    wait: "קיבלנו כבר את הבקשה שלך , אנא המתן מספר דקות בכדי לשלוח שוב.",
  },
  russian: {
    h1: "Свяжитесь с нами",
    h2: "Помогите нам помочь вам",
    btn: "Отправить",
    wait: "Мы уже получили ваше сообщение. Пожалуйста, подождите немного, прежде чем связываться с нами снова.",
  },
  arabic: {
    h1: "اتصل بنا",
    h2: "ساعدنا في مساعدتك",
    btn: "إرسال",
    wait: "لقد استلمنا رسالتك بالفعل. الرجاء الانتظار قليلاً قبل الاتصال بنا مرة أخرى.",
  },
};

const Contact = () => {
  const form: RefObject<HTMLFormElement> = useRef(null);
  const [submited, setSubmited] = useState(false);
  const {
    user: { language },
  } = useCustomContext();

  const [message, setMessage] = useState("");

  const sendEmail = async (e: any) => {
    e.preventDefault();
    const cookie = parseCookies(CookieName.Freeze);

    if (cookie) {
      setMessage(dict[language].wait);
      return;
    }

    setSubmited(true);
    const formData = new FormData(form.current!);

    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");
    const subject = formData.get("subject");
    const phone = formData.get("phone");

    try {
      //send req to server
      await serverAPI("users/contact", {
        fullName: name as string,
        email: email as string,
        message: message as string,
        subject: subject as string,
        phone: phone as string,
      });
    } catch (error: any) {
      console.log(error);
      setMessage(error.message);
    } finally {
      e.target.reset();
      setSubmited(false);
    }
  };

  return (
    <section id="contact">
      <div className="contact-form-class">
        <div style={{ fontSize: "3rem" }}>
          <Logo fontSize={"1em"} />
        </div>

        <div className="space-xl"></div>
        <div className="titles">
          <h2>{dict[language].h1}</h2>
          <h5>{dict[language].h2}</h5>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <Flex align="center" justify="center" gap={20}>
            <label htmlFor="subject">{dictAuth.subject[language]}</label>
            <select className="contact-input ui-input" name="subject" required>
              <option>אחר</option>
              <option>נגישות</option>
              <option>הצעת ייעול</option>
              <option>תלונה</option>
              <option>בעית הרשמה</option>
            </select>
          </Flex>
          <Flex align="center" justify="center" gap={20}>
            <label htmlFor="name">{dictAuth.fullName[language]}</label>
            <input
              className="contact-input ui-input"
              type="text"
              name="name"
              required
              placeholder={dictAuth.fullName[language]}
            />
          </Flex>
          <Flex align="center" justify="center" gap={20}>
            <label htmlFor="email">{dictAuth.email[language]}</label>
            <input
              className="contact-input ui-input"
              type="email"
              name="email"
              required
              placeholder={dictAuth.email[language]}
            />
          </Flex>
          <Flex align="center" justify="center" gap={20}>
            <label htmlFor="phone">{dictAuth.phoneNumber[language]}</label>
            <input
              className="contact-input ui-input"
              type="text"
              name="phone"
              placeholder={dictAuth.phoneNumber[language]}
            />
          </Flex>
          <Flex align="center" justify="center" gap={20}>
            <label htmlFor="message">{dictAuth.contactText[language]}</label>
            <textarea
              // placeholder="please fill"
              className="ui-input"
              name="message"
              rows={7}
              style={{ width: "100%", resize: "none" }}
              maxLength={350}
              required
            ></textarea>
          </Flex>
          <div className="titles">
            <Flex>
              <label></label>
              <Button width={"90%"} loading={submited}>
                {dict[language].btn}
              </Button>
            </Flex>
          </div>
        </form>
        <div>{message}</div>
      </div>
    </section>
  );
};

export default Contact;
