import React, { RefObject, useEffect } from "react";
import ReactDOM from "react-dom";
import { ChildrenRectPosition, TooltipPlacementType, tooltipSpacing } from "./Tooltip";

interface TooltipProps {
  visible: boolean;
  children: React.ReactNode;
  position: ChildrenRectPosition;
  maxWidth?: number;
  setTooltipWidth: (width: number) => void;
  tooltipRef: RefObject<HTMLDivElement>;
  placement: TooltipPlacementType;
  color?: string;
}

const TooltipPortal: React.FC<TooltipProps> = ({
  tooltipRef,
  visible,
  children,
  position,
  maxWidth,
  setTooltipWidth,
  placement,
  color = "rgba(0,0,0,0.88)",
}) => {
  useEffect(() => {
    if (visible && !maxWidth && tooltipRef.current) {
      setTooltipWidth(tooltipRef.current.offsetWidth);
    }
  }, [maxWidth, setTooltipWidth, visible, tooltipRef]);

  if (!visible || !children) return null;

  return ReactDOM.createPortal(
    <div
      className={`ui-tooltip-title ui-tooltip-title-${placement} animation-fade-in animation-fade-in-opacity`}
      ref={tooltipRef}
      style={
        {
          ...position,
          background: color,
          padding: tooltipSpacing,
          maxWidth: maxWidth,
          "--tooltip-bg-color": color,
        } as React.CSSProperties & { "--tooltip-bg-color"?: string }
      }
    >
      {children}
    </div>,
    document.body
  );
};

export default TooltipPortal;
