import { Dispatch, SetStateAction } from "react";
import serverAPI from "../../../api/server";
import { ProductDataType, StoreEn } from "../../../types/types";
import { fromBErawDataToFE } from "../ProductsV2/fetch_products_v2";
import { UsersProductsEP } from "../../../api/features/usersProducts.ep.types";
import { ProductsEP } from "../../../api/features/products.ep.types";

type UserRequestBody = UsersProductsEP["usersProducts/get/user"]["body"];
export type AutoRequestBody = ProductsEP["products/common/get"]["body"];

export type fetchSourceType = "auto" | "user";

type Args<T extends fetchSourceType> = {
  fetchSourceType: T;
  requestBody: T extends "auto" ? AutoRequestBody : UserRequestBody;
  setIsLoading: Dispatch<SetStateAction<{ auto: boolean; user: boolean; autoFinish: boolean }>>;
  setProducts: Dispatch<SetStateAction<ProductDataType[]>>;
  setProductsExtra?: Dispatch<SetStateAction<ProductDataType[]>>;
};

const getUrlPerSource = (fetchSourceType: fetchSourceType) => {
  switch (fetchSourceType) {
    case "auto":
      return `products/common/get`;
    case "user":
      return `usersProducts/get/user`;

    default:
      throw new Error("Not Selected Source Of DB - products main v1");
  }
};

const fetchProductsV1 = async <T extends fetchSourceType>({
  fetchSourceType,
  requestBody,
  setIsLoading,
  setProducts,
  setProductsExtra,
}: Args<T>) => {
  try {
    setIsLoading((p) => ({ ...p, [fetchSourceType]: true }));
    const url = getUrlPerSource(fetchSourceType);
    const { data, statusCode } = await serverAPI(url, requestBody as any, { description: "success" });

    if (statusCode === 200 && data.length) {
      if (fetchSourceType === "user" && setProductsExtra) {
        const { allUsers, userCustomProducts } = handleUserProductsData(data);
        setProducts(fromBErawDataToFE(userCustomProducts));

        // add all users only diferent items from userCustomProducts
        setProductsExtra(fromBErawDataToFE(allUsers));
      } else {
        setProducts(fromBErawDataToFE(data));
      }
    } else {
      // TODO : create popup that explain
      setProducts([]);
    }

    // todo if === 401 popup login
  } catch (e: any) {
    console.log(e);
  } finally {
    setIsLoading((p) => ({ ...p, [fetchSourceType]: false, autoFinish: true }));
  }
};

export default fetchProductsV1;

// * ----------- HELPER
type userProductsData = { favorite_item_code: string; max_count: ProductDataType; user_preference: ProductDataType };

const handleUserProductsData = (data: userProductsData[]) => {
  const allUsers: ProductDataType[] = [];
  const userCustomProducts: ProductDataType[] = [];
  data.forEach((item) => {
    // v1 doesnt support on null item so we will filter it now and try handle it later
    const stores = Object.keys(item.user_preference);
    const hasNonValidStore = stores.every((store) => {
      return item.user_preference[store as StoreEn] !== null;
    });
    // console.log(hasNonValidStore);
    if (!hasNonValidStore) return;

    userCustomProducts.push(item.user_preference);

    //todo: consider filter out items that already found in userCustomProducts
    //* -- why not : alow people to get update to new items
    allUsers.push(item.max_count);
  });
  // console.log(userCustomProducts);
  return { userCustomProducts, allUsers };
};
