import { Tooltip } from "../../../components/UI";
import { InfoIcon } from "../../../components/UI/Icons";

export const DrawFooterBox = ({
  toolTipTitle,
  description,
  price,
}: {
  toolTipTitle?: string;
  description: string;
  price?: string;
}) => {
  return (
    <div className="cart-comparison_cart_footer_box">
      <span>
        {toolTipTitle ? (
          <Tooltip title={toolTipTitle}>
            <InfoIcon />
          </Tooltip>
        ) : (
          ""
        )}
      </span>

      <span>{description}</span>
      {price && (
        <div>
          <span>{price}</span>
          <span style={{ fontSize: 12 }}> ש"ח </span>
        </div>
      )}
    </div>
  );
};
