import { StoreEn } from "../types/types";

type StoreTranslations = { [lang: string]: string };

export const dictStores: Record<StoreEn, StoreTranslations> = {
  victory: {
    hebrew: "ויקטורי",
    english: "Victory",
    russian: "Виктори",
    arabic: "فيكتوري",
    symbol: "",
  },
  bitan: {
    hebrew: "יינות ביתן",
    english: "Bitan",
    russian: "Битан",
    arabic: "بيتان",
    symbol: "",
  },
  mahsane: {
    hebrew: "מחסני השוק",
    english: "Mahsane Hashuk",
    russian: "Махсане Хашук",
    arabic: "محسنة السوق",
    symbol: "",
  },
  // mega: {
  //   hebrew: "מגה",
  //   english: "Mega",
  //   russian: "Мега",
  //   arabic: "ميجا",
  //   symbol: "",
  // },
  shufersal: {
    hebrew: "שופרסל",
    english: "Shufersal",
    russian: "Шуферсал",
    arabic: "شوفيرسال",
    symbol: "",
  },
  rami: {
    hebrew: "רמי לוי",
    english: "Rami Levi",
    russian: "Рами Леви",
    arabic: "رامي ليفي",
    symbol: "",
  },
  carrefour: {
    hebrew: "קרפור",
    english: "Carrefour",
    russian: "Карфур",
    arabic: "كارفور",
    symbol: "",
  },
  quick: {
    hebrew: "קוויק",
    english: "Quick",
    russian: "Квик",
    arabic: "كويك",
    symbol: "",
  },
};
