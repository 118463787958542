import { ThemeInitialValue } from "../../../hooks/useCustomContext/helpers/initialValues";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { Button } from "../../UI";
import AccessabilityOption from "./AccesabilityContentOptions/AccessabilityOption";

// 🔍  🧭

// ideas
// https://userway.org/l/heb/?utm_source=google&utm_medium=cpc&utm_campaign=israel%20|%20hebrew%20|%20competitor%20|%20search%20|%20terrific&utm_content=webaim&utm_ad=673435950687&utm_term=webaim&matchtype=p&device=c&GeoLoc=1007966&placement=&network=g&utm_id=20542826878&campaign_id=20542826878&adset_id=153356262677&ad_id=673435950687&cq_src=google_ads&cq_cmp=20542826878&cq_con=153356262677&cq_term=webaim&cq_med=&cq_plac=&cq_net=g&cq_pos=&cq_plt=gp&keyword_id=kwd-455722291850&gclid=Cj0KCQjw6oi4BhD1ARIsAL6pox3qIslaOgK1KyJ0kJJKpeKnnw1Ud5vOaFysFm3Aahc5Yt4xEr7gZNcaAuGKEALw_wcB

const AccessabilityContent = () => {
  const {
    user: { language },
    theme,
    setTheme,
  } = useCustomContext();

  const handleResetToDefault = () => {
    setTheme(ThemeInitialValue);
  };

  return (
    <div className="accessability-content-container animation-fade-in animation-fade-in-opacity">
      <section>
        <AccessabilityOption
          uiType="segmented"
          theme={theme}
          setTheme={setTheme}
          language={language}
          themeType="contrast"
          icon={<div className="contrast_circle_icon"></div>}
        />
      </section>
      <section>
        <AccessabilityOption
          uiType="segmented"
          theme={theme}
          setTheme={setTheme}
          language={language}
          themeType="fontSize"
          icon={
            <div className="font_icon">
              <span>T</span>
              <span>T</span>
            </div>
          }
        />
      </section>
      <section>
        <AccessabilityOption
          uiType="segmented"
          theme={theme}
          setTheme={setTheme}
          language={language}
          themeType="wordSpacing"
          icon={<p style={{ color: "var(--font-color)" }}>&#10094; &#9477; &#10095;</p>}
        />
      </section>
      <section>
        <AccessabilityOption
          uiType="toggle"
          language={language}
          themeType="showLinksUnderline"
          theme={theme}
          setTheme={setTheme}
        />
      </section>
      <section>
        <AccessabilityOption
          uiType="toggle"
          language={language}
          themeType="showAnimation"
          theme={theme}
          setTheme={setTheme}
        />
      </section>
      {/* <section>ניווט קולי - פיתוח ארוך</section> */}
      {/* <section>סמן עכבר רגיל, גדול , לקריאה , מגדיל</section> */}
      {/* <section>messages of actions status</section> */}
      <section>
        <Button onClick={handleResetToDefault}>Default</Button>
      </section>
    </div>
  );
};

export default AccessabilityContent;
