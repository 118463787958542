import { Dispatch, ReactNode, SetStateAction, useMemo } from "react";
import { Language } from "../../../../types/types";
import { Card, Flex, Segmented } from "../../../UI";
import Toggle from "../../../UI/Toggle/Toggle";

export type ContrastType = "theme-dark" | "theme-high-contrast" | "theme-light";
export type FontSizeType = "theme-font-normal" | "theme-font-large" | "theme-font-xl";
export type wordSpacingType = "theme-word-spacing-normal" | "theme-word-spacing-large" | "theme-word-spacing-xl";

export type ThemeType = {
  contrast: ContrastType;
  fontSize: FontSizeType;
  showLinksUnderline: "" | "theme-show_link_underline";
  showAnimation: "" | "theme-disable-animations";
  wordSpacing: wordSpacingType;
};

interface LanguageDict {
  hebrew: { title: string; options: Record<string, string> };
  english: { title: string; options: Record<string, string> };
  arabic: { title: string; options: Record<string, string> };
  russian: { title: string; options: Record<string, string> };
}

interface IProps {
  language: Language;
  theme: ThemeType;
  setTheme: Dispatch<SetStateAction<ThemeType>>;
  themeType: keyof ThemeType;
  icon?: ReactNode;
  uiType: "segmented" | "toggle";
}

const AccessabilityOption = ({ uiType, language, setTheme, theme, themeType, icon }: IProps) => {
  const handleOnChange = (value: ThemeType[typeof themeType]) => {
    setTheme((p) => ({ ...p, [themeType]: value }));
  };

  const dict: Record<keyof ThemeType, LanguageDict> = useMemo(() => {
    return {
      contrast: {
        hebrew: {
          title: "ניגודיות",
          options: { "theme-dark": "כהה", "theme-high-contrast": "ניגוד גבוה", "theme-light": "בהיר" },
        },
        english: {
          title: "Contrast",
          options: { "theme-dark": "Dark", "theme-high-contrast": "High Contrast", "theme-light": "Light" },
        },
        arabic: {
          title: "تباين",
          options: { "theme-dark": "داكن", "theme-high-contrast": "تباين عالٍ", "theme-light": "فاتح" },
        },
        russian: {
          title: "Контраст",
          options: { "theme-dark": "Тёмный", "theme-high-contrast": "Высокий контраст", "theme-light": "Светлый" },
        },
      },
      fontSize: {
        hebrew: {
          title: "גודל טקסט",
          options: { "theme-font-normal": "רגיל", "theme-font-large": "גדול", "theme-font-xl": "גדול מאוד" },
        },
        english: {
          title: "Text Size",
          options: { "theme-font-normal": "Medium", "theme-font-large": "Large", "theme-font-xl": "Extra Large" },
        },
        arabic: {
          title: "حجم النص",
          options: { "theme-font-normal": "عادي", "theme-font-large": "كبير", "theme-font-xl": "كبير جدًا" },
        },
        russian: {
          title: "Размер текста",
          options: { "theme-font-normal": "Обычный", "theme-font-large": "Большой", "theme-font-xl": "Очень большой" },
        },
      },
      showLinksUnderline: {
        hebrew: { title: "הדגשת קישורים", options: { true: "theme-show_link_underline" } },
        english: { title: "Underline Links", options: { true: "theme-show_link_underline" } },
        arabic: { title: "تسطير الروابط", options: { true: "theme-show_link_underline" } },
        russian: { title: "Подчеркнуть ссылки", options: { true: "theme-show_link_underline" } },
      },
      showAnimation: {
        hebrew: { title: "ביטול אנימציות", options: { true: "theme-disable-animations" } },
        english: { title: "Disable Animations", options: { true: "theme-disable-animations" } },
        arabic: { title: "تعطيل الرسوم المتحركة", options: { true: "theme-disable-animations" } },
        russian: { title: "Отключить анимации", options: { true: "theme-disable-animations" } },
      },
      wordSpacing: {
        hebrew: {
          title: "ריווח טקסט",
          options: {
            "theme-word-spacing-normal": "רגיל",
            "theme-word-spacing-large": "גדול",
            "theme-word-spacing-xl": "גדול מאוד",
          },
        },
        english: {
          title: "Text Spacing",
          options: {
            "theme-word-spacing-normal": "Medium",
            "theme-word-spacing-large": "Large",
            "theme-word-spacing-xl": "Extra Large",
          },
        },
        arabic: {
          title: "تباعد النص",
          options: {
            "theme-word-spacing-normal": "عادي",
            "theme-word-spacing-large": "كبير",
            "theme-word-spacing-xl": "كبير جدًا",
          },
        },
        russian: {
          title: "Интервал текста",
          options: {
            "theme-word-spacing-normal": "Обычный",
            "theme-word-spacing-large": "Большой",
            "theme-word-spacing-xl": "Очень большой",
          },
        },
      },
    };
  }, []);

  // Get the dictionary based on the `type` and language
  const { title, options } = dict[themeType][language];

  if (uiType === "toggle") {
    return (
      <Card style={{ width: "100%" }}>
        <Flex style={{ width: "100%" }} justify="space-between">
          <span>{title}</span>
          <span>
            <Toggle
              // @ts-ignore
              onChange={(v) => handleOnChange(v ? options["true"] : "")}
              checked={theme[themeType] === "" ? false : true}
            />
          </span>
        </Flex>
      </Card>
    );
  }

  return (
    <Card title={title} iconL={icon} shadow>
      <Segmented
        // @ts-ignore
        defaultKey={theme[themeType]}
        key={themeType}
        options={Object.keys(options).map((key) => ({
          key,
          label: options[key],
        }))}
        onChange={(v) => handleOnChange(v.key as ThemeType[typeof themeType])}
      />
    </Card>
  );
};

export default AccessabilityOption;
