import React, { useState, useReducer, Dispatch, SetStateAction, FC } from "react";
import serverAPI from "../../../api/server";
import InputUI from "../../UI/Input/Input";
import { Button } from "../../UI";
import "../Authentication.css";
import { EmailIcon } from "../../UI/Icons";
import { AuthenticationFormReducer, AuthenticationInitialState } from "../AuthReducer/AuthReducer";
import { dictAuth } from "../dictionary";

import { AuthType } from "../Authentication";
import InfoBox, { InfoBoxType } from "../../UI/InfoBox/InfoBox";

const language = "hebrew";

type Props = {
  setAuthType: Dispatch<SetStateAction<AuthType>>;
  handleClose: () => void;
};

const ForgetPassword: FC<Props> = ({ setAuthType, handleClose }) => {
  const [formState, dispatch] = useReducer(AuthenticationFormReducer, AuthenticationInitialState);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<{ text: string; type: InfoBoxType }>({
    text: "",
    type: "gray",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await serverAPI("users/forget-password", { email: formState.email });
      if (res.statusCode === 200) {
        setResponseMessage({ text: dictAuth.emailSent[language], type: "success" });
        dispatch({ type: "SET_EMAIL" as any, payload: "" });

        setTimeout(() => {
          setResponseMessage({ text: dictAuth.closeWindow[language], type: "info" });
        }, 3000);
        // handleClose();
      } else {
        setResponseMessage({ text: dictAuth.userNotFound[language], type: "warning" });
      }
    } catch (error: any) {
      setResponseMessage({ text: error.message, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <InputUI
            id="email"
            htmlFor="email"
            name="SET_EMAIL"
            required
            type="email"
            language={language}
            label={dictAuth.email[language]}
            value={formState.email}
            onChange={handleInputChange}
            icon={<EmailIcon size={"50%"} color="var(--primary)" />}
          />
        </div>

        <div className="form-group">
          <Button htmlType="submit" loading={loading}>
            {dictAuth.sendResetPasswordEmail[language]}
          </Button>
        </div>

        <p className="redirect-to">
          <span onClick={() => setAuthType("register")}>{dictAuth.notAMember[language]}</span>
          <span onClick={() => setAuthType("login")}>{dictAuth.alreadyAMember[language]}</span>
        </p>
      </form>

      {responseMessage.text && (
        <InfoBox
          cssClass="response-message"
          content={responseMessage.text}
          type={responseMessage.type}
          language={language}
        />
      )}
    </div>
  );
};

export default ForgetPassword;
