import { LegalTerms } from "../LegalPolicy";

const date = "30/09/2024";
const email = "tozilu@gmail.com";

const termsOfUseDataV1: LegalTerms = {
  hebrew: {
    title: 'תנאי שימוש לאתר "תוזילו"',
    lastUpdated: `תאריך עדכון אחרון: [${date}]`,
    sections: [
      {
        index: 1,
        title: "השימוש באתר",
        content:
          "האתר מספק למשתמשים אפשרות להשוות מחירי רשתות מזון על בסיס נתוני החנויות המקוונות שלהן. המשתמש רשאי למלא עגלה באתר ולצפות בהשוואת מחירים עבור פריטים זהים או דומים בין רשתות שונות. לאחר מכן, המשתמש יוכל לשגר את העגלה לאתר רשת המזון הנבחרת להשלמת הרכישה.",
      },
      {
        index: 2,
        title: "איסוף ושימוש במידע אישי",
        content:
          'בעת השימוש באתר, אנו עשויים לאסוף מידע אישי הנדרש לצורך השירותים הניתנים. המידע עשוי לכלול: פרטי חשבון משתמש ברשתות מזון (שם משתמש וסיסמה) לשם העברת העגלה לאתרי הרשתות. אנו לא שומרים את פרטי ההתחברות מעבר לביצוע הפעולה הנדרשת. מידע על העגלות שמולאו, היסטוריית רכישות, ומשוב על מוצרים. נתוני התקשרות כגון דוא"ל או מספר טלפון, לצורך קבלת פידבק מהמשתמשים.',
      },
      {
        index: 3,
        title: "אחריות המשתמש",
        content:
          "המשתמש אחראי לשימוש נכון ובטוח באתר, ובפרט לשמירה על פרטיות פרטי ההתחברות שלו לרשתות מזון. אנו ממליצים למשתמש לשנות את הסיסמה באופן קבוע.",
      },
      {
        index: 4,
        title: "הגבלת אחריות",
        content:
          "האתר מספק שירותי השוואת מחירים על בסיס נתונים ציבוריים שנאספו מהחנויות המקוונות של רשתות המזון הגדולות. אנו עושים כל מאמץ לעדכן את הנתונים בזמן אמת, אך ייתכנו שגיאות או הבדלים בין המידע המוצג באתר לבין המחירים בפועל ברשתות המזון. האתר לא יישא באחריות לכל הפסד, נזק או אי דיוק שנגרם כתוצאה מהסתמכות על המידע באתר.",
      },
      {
        index: 5,
        title: "תקשורת עם המשתמשים",
        content:
          "אנו עשויים ליצור קשר עם המשתמשים לצורך קבלת משוב על מוצרים שנרכשו באמצעות האתר. המידע שייאסף ייעשה בו שימוש לצורך שיפור השירות, ניתוח איכות המוצרים, והשוואת מחירים תוך התחשבות באיכות.",
      },
      {
        index: 6,
        title: "נגישות",
        content:
          "אנו מחויבים להבטיח שהאתר נגיש לכלל הציבור בהתאם לחוק הנגישות הישראלי. אם נתקלת בבעיה כלשהי בנגישות האתר, אנו מזמינים אותך ליצור קשר עמנו לתיקון.",
      },
      {
        index: 7,
        title: "שפות תמיכה",
        content:
          "האתר תומך בשפות שונות לרבות עברית, אנגלית, רוסית וערבית. עם זאת, ייתכן וחלק מהתכנים באתר לא יהיו זמינים מיידית בכל השפות. במקרה של חוסר התאמה או הבדל בין הגרסאות המתורגמות של תנאי השימוש, תנאי השימוש בעברית הם אלו שיחייבו.",
      },
      {
        index: 8,
        title: "שינויים באתר ובתנאי השימוש",
        content:
          "אנו שומרים את הזכות לעדכן או לשנות את תנאי השימוש מעת לעת בהתאם לשינויים בשירותים המוצעים או בדרישות החוק. כל שינוי ייכנס לתוקף מיידית עם פרסומו באתר.",
      },
      {
        index: 9,
        title: "תשלום ועמלות",
        content:
          "כיום השירות באתר ניתן ללא עלות. עם זאת, בעתיד אנו עשויים לגבות עמלות על השימוש באתר, בין אם מצד המשתמשים ובין אם מצד רשתות המזון.",
      },
      {
        index: 10,
        title: "זכויות יוצרים וקניין רוחני",
        content:
          "כל הזכויות באתר, בתכניו ובעיצובו, לרבות זכויות הקניין הרוחני, שמורות לנו. אין להעתיק, לשכפל, להפיץ, או לעשות כל שימוש בתכנים ללא אישור מראש ובכתב מאת בעלי האתר.",
      },
      {
        index: 11,
        title: "יצירת קשר",
        content: `לכל שאלה או בירור בנוגע לתנאי השימוש או השימוש באתר, אנא פנו אלינו בכתובת [${email}].`,
      },
    ],
  },
  english: {
    title: "Terms of Use for 'Tuzilu' Website",
    lastUpdated: `Last updated: [${date}]`,
    sections: [
      {
        index: 1,
        title: "Use of the Website",
        content:
          "The website provides users with the ability to compare prices of food chains based on data from their online stores. The user may fill a cart on the website and view price comparisons for identical or similar items across different chains. Afterward, the user can send the cart to the selected food chain's website to complete the purchase.",
      },
      {
        index: 2,
        title: "Collection and Use of Personal Information",
        content:
          "When using the website, we may collect personal information necessary for the services provided. This information may include: user account details for food chains (username and password) for transferring the cart to their websites. We do not store login details beyond the required operation. Information about filled carts, purchase history, and feedback on products. Contact information such as email or phone number for receiving user feedback.",
      },
      {
        index: 3,
        title: "User Responsibility",
        content:
          "The user is responsible for using the website correctly and safely, especially in maintaining the privacy of their login details for the food chains. We recommend that users change their passwords regularly.",
      },
      {
        index: 4,
        title: "Limitation of Liability",
        content:
          "The website provides price comparison services based on public data collected from the online stores of major food chains. We make every effort to update the data in real-time, but there may be errors or differences between the information displayed on the website and the actual prices at the food chains. The website is not liable for any loss, damage, or inaccuracies resulting from reliance on the information on the website.",
      },
      {
        index: 5,
        title: "Communication with Users",
        content:
          "We may contact users to receive feedback on products purchased through the website. The collected information will be used to improve the service, analyze product quality, and compare prices with consideration of quality.",
      },
      {
        index: 6,
        title: "Accessibility",
        content:
          "We are committed to ensuring that the website is accessible to the public in accordance with Israeli accessibility law. If you encounter any issues with the accessibility of the website, please contact us for correction.",
      },
      {
        index: 7,
        title: "Language Support",
        content:
          "The website supports multiple languages, including Hebrew, English, Russian, and Arabic. However, some content may not be immediately available in all languages. In the event of a discrepancy or inconsistency between the translated versions of the terms of use, the Hebrew version shall prevail.",
      },
      {
        index: 8,
        title: "Changes to the Website and Terms of Use",
        content:
          "We reserve the right to update or modify the terms of use from time to time in accordance with changes in the services offered or legal requirements. Any changes will take effect immediately upon their publication on the website.",
      },
      {
        index: 9,
        title: "Payments and Fees",
        content:
          "Currently, the service on the website is provided free of charge. However, in the future, we may charge fees for the use of the website, whether from users or from food chains.",
      },
      {
        index: 10,
        title: "Copyrights and Intellectual Property",
        content:
          "All rights to the website, its content, and its design, including intellectual property rights, are reserved by us. No content may be copied, reproduced, distributed, or used in any way without prior written permission from the site owners.",
      },
      {
        index: 11,
        title: "Contact Information",
        content: `For any questions or inquiries regarding the terms of use or the use of the website, please contact us at [${email}].`,
      },
    ],
  },
  russian: {
    title: "Правила использования сайта 'Тузилу'",
    lastUpdated: `Последнее обновление: [${date}]`,
    sections: [
      {
        index: 1,
        title: "Использование сайта",
        content:
          "Сайт предоставляет пользователям возможность сравнивать цены продуктовых сетей на основе данных из их онлайн-магазинов. Пользователь может заполнить корзину на сайте и увидеть сравнение цен на идентичные или аналогичные товары в разных сетях. После этого пользователь может отправить корзину на выбранный сайт продуктовой сети для завершения покупки.",
      },
      {
        index: 2,
        title: "Сбор и использование персональной информации",
        content:
          "При использовании сайта мы можем собирать персональные данные, необходимые для предоставления услуг. Эти данные могут включать: учетные данные пользователя для продуктовых сетей (имя пользователя и пароль) для передачи корзины на их сайты. Мы не храним учетные данные после выполнения необходимой операции. Информация о заполненных корзинах, истории покупок и отзывах на товары. Контактная информация, такая как электронная почта или номер телефона, для получения отзывов пользователей.",
      },
      {
        index: 3,
        title: "Ответственность пользователя",
        content:
          "Пользователь несет ответственность за правильное и безопасное использование сайта, особенно за сохранение конфиденциальности своих учетных данных для продуктовых сетей. Мы рекомендуем пользователям регулярно менять свои пароли.",
      },
      {
        index: 4,
        title: "Ограничение ответственности",
        content:
          "Сайт предоставляет услуги по сравнению цен на основе общедоступных данных, собранных из онлайн-магазинов крупных продуктовых сетей. Мы прилагаем все усилия для обновления данных в реальном времени, но могут возникнуть ошибки или различия между информацией, отображаемой на сайте, и фактическими ценами в продуктовых сетях. Сайт не несет ответственности за любые убытки, ущерб или неточности, возникающие вследствие доверия к информации, размещенной на сайте.",
      },
      {
        index: 5,
        title: "Общение с пользователями",
        content:
          "Мы можем связываться с пользователями для получения отзывов о товарах, купленных через сайт. Собранная информация будет использоваться для улучшения сервиса, анализа качества продукции и сравнения цен с учетом качества.",
      },
      {
        index: 6,
        title: "Доступность",
        content:
          "Мы стремимся обеспечить, чтобы сайт был доступен для всех пользователей в соответствии с израильским законом о доступности. Если вы столкнулись с какими-либо проблемами с доступностью сайта, свяжитесь с нами для их устранения.",
      },
      {
        index: 7,
        title: "Поддержка языков",
        content:
          "Сайт поддерживает несколько языков, включая иврит, английский, русский и арабский. Однако некоторый контент может быть не сразу доступен на всех языках. В случае расхождений или несоответствий между переведёнными версиями правил использования, приоритет будет отдан ивритской версии.",
      },
      {
        index: 8,
        title: "Изменения на сайте и правила использования",
        content:
          "Мы оставляем за собой право время от времени обновлять или изменять правила использования в соответствии с изменениями в предоставляемых услугах или требованиями законодательства. Любые изменения вступают в силу сразу после их публикации на сайте.",
      },
      {
        index: 9,
        title: "Платежи и сборы",
        content:
          "В настоящее время услуги на сайте предоставляются бесплатно. Однако в будущем мы можем взимать плату за использование сайта, как с пользователей, так и с продуктовых сетей.",
      },
      {
        index: 10,
        title: "Авторские права и интеллектуальная собственность",
        content:
          "Все права на сайт, его контент и дизайн, включая права на интеллектуальную собственность, защищены. Никакой контент не может быть скопирован, воспроизведен, распространён или использован каким-либо образом без предварительного письменного разрешения владельцев сайта.",
      },
      {
        index: 11,
        title: "Контактная информация",
        content: `Для любых вопросов или запросов относительно правил использования или использования сайта, пожалуйста, свяжитесь с нами по адресу [${email}].`,
      },
    ],
  },
  arabic: {
    title: "شروط استخدام موقع 'توزيلو'",
    lastUpdated: `آخر تحديث: [{date}]`,
    sections: [
      {
        index: 1,
        title: "استخدام الموقع",
        content:
          "يتيح الموقع للمستخدمين مقارنة أسعار شبكات الغذاء بناءً على بيانات من متاجرهم عبر الإنترنت. يمكن للمستخدم ملء سلة التسوق على الموقع، ومشاهدة مقارنة الأسعار للمنتجات المماثلة أو المشابهة في المتاجر المختلفة. بعد ذلك، يمكن للمستخدم إرسال السلة إلى المتجر المختار لإكمال الشراء.",
      },
      {
        index: 2,
        title: "جمع المعلومات الشخصية واستخدامها",
        content:
          "عند استخدام الموقع، قد نقوم بجمع البيانات الشخصية اللازمة لتقديم الخدمات. قد تتضمن هذه البيانات: بيانات تسجيل الدخول إلى شبكات الغذاء (اسم المستخدم وكلمة المرور) لإرسال السلة إلى مواقعهم. لن نقوم بحفظ بيانات تسجيل الدخول بعد إتمام العملية المطلوبة. معلومات حول السلال المعبأة، تاريخ المشتريات، والتقييمات للمنتجات. معلومات الاتصال، مثل البريد الإلكتروني أو رقم الهاتف، للحصول على ملاحظات المستخدمين.",
      },
      {
        index: 3,
        title: "مسؤولية المستخدم",
        content:
          "المستخدم مسؤول عن الاستخدام الصحيح والآمن للموقع، خاصة فيما يتعلق بالحفاظ على سرية بيانات تسجيل الدخول الخاصة بشبكات الغذاء. ننصح المستخدمين بتغيير كلمات المرور بشكل منتظم.",
      },
      {
        index: 4,
        title: "حدود المسؤولية",
        content:
          "يقدم الموقع خدمات مقارنة الأسعار بناءً على بيانات عامة مأخوذة من متاجر الغذاء عبر الإنترنت. نبذل قصارى جهدنا لتحديث البيانات بانتظام، لكن قد تحدث أخطاء أو اختلافات بين المعلومات المعروضة على الموقع والأسعار الفعلية في شبكات الغذاء. الموقع غير مسؤول عن أي خسائر أو أضرار أو عدم دقة تنشأ عن الاعتماد على المعلومات المعروضة على الموقع.",
      },
      {
        index: 5,
        title: "التواصل مع المستخدمين",
        content:
          "قد نتواصل مع المستخدمين للحصول على ملاحظات حول المنتجات التي تم شراؤها عبر الموقع. سيتم استخدام المعلومات المجمعة لتحسين الخدمة، وتحليل جودة المنتجات، ومقارنة الأسعار مع مراعاة الجودة.",
      },
      {
        index: 6,
        title: "إمكانية الوصول",
        content:
          "نحن ملتزمون بجعل الموقع متاحًا لجميع المستخدمين وفقًا للقانون الإسرائيلي حول إمكانية الوصول. إذا واجهتم أي مشاكل تتعلق بإمكانية الوصول إلى الموقع، يرجى التواصل معنا لحلها.",
      },
      {
        index: 7,
        title: "دعم اللغات",
        content:
          "يدعم الموقع عدة لغات، بما في ذلك العبرية والإنجليزية والروسية والعربية. ومع ذلك، قد لا يكون بعض المحتوى متاحًا فورًا بجميع اللغات. في حالة وجود اختلافات أو تناقضات بين النسخ المترجمة لشروط الاستخدام، سيتم إعطاء الأولوية للنسخة العبرية.",
      },
      {
        index: 8,
        title: "التغييرات في الموقع وشروط الاستخدام",
        content:
          "نحتفظ بالحق في تحديث أو تعديل شروط الاستخدام من حين لآخر وفقًا للتغييرات في الخدمات المقدمة أو المتطلبات القانونية. أي تغييرات ستصبح سارية فور نشرها على الموقع.",
      },
      {
        index: 9,
        title: "المدفوعات والرسوم",
        content:
          "حاليًا، تُقدم الخدمات على الموقع مجانًا. ومع ذلك، قد نفرض رسومًا في المستقبل مقابل استخدام الموقع، سواء من المستخدمين أو شبكات الغذاء.",
      },
      {
        index: 10,
        title: "حقوق الطبع والنشر والملكية الفكرية",
        content:
          "جميع حقوق الموقع، محتوياته وتصميمه، بما في ذلك حقوق الملكية الفكرية، محفوظة. لا يمكن نسخ أو إعادة إنتاج أو توزيع أو استخدام أي محتوى بأي شكل من الأشكال دون الحصول على إذن كتابي مسبق من مالكي الموقع.",
      },
      {
        index: 11,
        title: "معلومات الاتصال",
        content: `لأي استفسارات أو طلبات بخصوص شروط الاستخدام أو استخدام الموقع، يرجى التواصل معنا عبر البريد الإلكتروني [${email}].`,
      },
    ],
  },
};

export default termsOfUseDataV1;
