import { FormEvent, useReducer, useState } from "react";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { Button, Card, Checkbox, Flex, SelectSearchUI } from "../../UI";
import { dictAuth } from "../../Authentication/dictionary";
import PersonalData from "../../Authentication/Register/tabs/PersonalData";
import { AuthenticationFormReducer, AuthenticationFormState } from "../../Authentication/AuthReducer/AuthReducer";
import storesLocation from "../../../assets/Data/storesLocations";
import { isValidIsraelPhoneNumber } from "../../../utils/utils";
import { UpdateUserRequest } from "../../../api/features/users.ep.types";
import serverAPI from "../../../api/server";
import AlertMessage from "../../UI/AlertMessage/AlertMessage";
import csrfToken from "../../../models/Tokens/CsrfToken";

const PrivacySettings = () => {
  const {
    user: { fullName, language, email, phoneNumber, city },
    setUser,
  } = useCustomContext();

  const [cityState, setCityState] = useState(city);

  const AuthenticationInitialState: AuthenticationFormState = {
    fullName,
    email,
    phoneNumber,
    language,
    city: city || "",
    password: "",
    confirmPassword: "",
  };

  const [message, setMessage] = useState<{ text: string; type: "success" | "error" }>();

  const validation = () => {
    if (formState.phoneNumber && !isValidIsraelPhoneNumber(formState.phoneNumber)) return false;
    else if (formState.fullName.length < 3) return false;
    return true;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validation()) {
      const request: UpdateUserRequest["body"] = {
        fullName: formState.fullName,
        email: formState.email,
        city: cityState,
        phoneNumber: formState.phoneNumber,
      };

      const res = await serverAPI("users/update", request);
      if (res.statusCode === 201) {
        setUser((p) => ({ ...p, ...request }));
        csrfToken.setToken(res.data.csrfToken);
      }
      setMessage({ text: res.data.content, type: res.data.type });
    } else {
      setMessage({ type: "error", text: "form is not valid" });
    }

    setTimeout(() => {
      setMessage({ text: "", type: "error" });
    }, 5000);
  };

  const [formState, dispatch] = useReducer(AuthenticationFormReducer, AuthenticationInitialState);

  const handleSelectCity = (option: any) => {
    if (option[0] && option[0].value !== cityState) {
      setCityState(option[0].value);
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} className="container flex-column flex-gap-20 flex-space-around">
        <div className="space-height-xl"></div>
        <div className="responsive-flex-column flex-center flex-gap-20">
          <Card subtitle={"פרטי משתמש"} cssClass="profile-setting-card-aside">
            <SelectSearchUI
              label={dictAuth.city[language]}
              placeholder="יישוב"
              onChange={handleSelectCity}
              disableLabelInInput
              values={[{ value: cityState ?? "", label: cityState ?? "" }]}
              options={storesLocation.map((s) => ({ label: s.location, value: s.location.trim(), data: s.stores }))}
              required
            />
            <div className="space-height-md"></div>
            <PersonalData source="privacySettings" language={language} dispatch={dispatch} formState={formState} />
          </Card>
          <Card cssClass="profile-setting-card-aside" subtitle={"הסכמי דילוור"}>
            <Flex flexDirection="column" gap={30}>
              <Card>
                <Checkbox label="אני מסכים לקבל הודעות למייל" />
                <div style={{ padding: "3px 40px" }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum voluptatem fugit ipsa, dolores, officia
                  quam ab n
                </div>
              </Card>
              <Card>
                <Checkbox label="אני מסכים לקבל הודעות לוואטספ" />
                <div style={{ padding: "3px 40px" }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum voluptatem fugit ipsa, dolores, officia
                  quam ab n
                </div>
              </Card>
              <Card>
                <Checkbox label="אני מסכים שתשמרו את פרטי החיבור שלי בדפדפן בלבד" />
                <div style={{ padding: "3px 40px" }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum voluptatem fugit ipsa, dolores, officia
                  quam ab n
                </div>
              </Card>
            </Flex>
          </Card>
        </div>
        <Button
          disabled={JSON.stringify({ ...formState, city: cityState }) === JSON.stringify(AuthenticationInitialState)}
          // width={300}
        >
          שמירת שינויים
        </Button>
      </form>
      {message?.text && <AlertMessage delay={5} message={message?.text} type={message?.type} position="bottom" />}
    </>
  );
};

export default PrivacySettings;
