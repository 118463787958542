import "./CartComparisonAllCartsView.css";
import { dictStores } from "../../../../dictionary/dictStores";
import { StoreEn } from "../../../../types/types";
import { getMissingItems } from "../../Helpers/Utils";
import { DrawFooterBox } from "../../Helpers/Ui";
import { cartComparisonExplain } from "../../Helpers/dict";
import { Button } from "../../../../components/UI";
import useCustomContext from "../../../../hooks/useCustomContext/useCustomContext";
import PriceCalculator from "../../../../models/ItemPriceCalculator/PriceCalculator";
import drawCartItems from "../../../../components/Cart/CartContent/helper/drawCartItems";
import { useState } from "react";

interface ICartComparisonCarouselViewProps {
  handleSelectStore: (store: StoreEn) => void;
}

const CartComparisonAllCartsView = ({ handleSelectStore }: ICartComparisonCarouselViewProps) => {
  const {
    cart,
    user: { language },
    onlineStores: { favoriteStore, otherStores },

    // siteVersion
  } = useCustomContext();

  const cartItems = Object.keys(cart);
  const [scrollTop, setScrollTop] = useState(0);

  const calculateStoreTotalPrice = (store: StoreEn) => {
    return PriceCalculator.getTotalCartCost(store, cart).toFixed(2);
  };

  return (
    <div onScroll={(e) => setScrollTop(e.currentTarget.scrollTop)} className="cart-comparison_all_wrapper scrollable">
      <div className="cart-comparison_cart_wrapper">
        <h2 className="cart-comparison_cart_header">{dictStores[favoriteStore][language]}</h2>
        <div style={{ flex: 1 }}>
          {drawCartItems(cartItems, cart, favoriteStore, { featureName: "comparison_cart", scrollTop })}
        </div>
        <h2 className="cart-comparison_cart_footer">
          <DrawFooterBox description="" />

          <DrawFooterBox
            description={cartComparisonExplain.hebrew.originalTotal}
            price={calculateStoreTotalPrice(favoriteStore)}
          />
          <Button onClick={() => handleSelectStore(favoriteStore)}>Go To</Button>
        </h2>
      </div>

      <div className="cart-comparison_others_wrapper scrollable">
        {otherStores.map((store) => {
          const missingItems = getMissingItems(cart, favoriteStore, store);
          return (
            <div key={store} style={{ flex: 1 }} className="cart-comparison_cart_wrapper">
              <h2 className="cart-comparison_cart_header">{dictStores[store][language]}</h2>
              <div style={{ flex: 1 }}>
                {drawCartItems(cartItems, cart, store, { featureName: "comparison_cart", scrollTop })}
              </div>
              <h2 className="cart-comparison_cart_footer">
                {missingItems.length > 0 ? (
                  <DrawFooterBox
                    toolTipTitle={`items that missing : ${missingItems.join(", \n")}`}
                    description={`חסרים ${missingItems.length} פריטים`}
                  />
                ) : (
                  <DrawFooterBox toolTipTitle={""} description={""} />
                )}
                <DrawFooterBox
                  toolTipTitle={`סה"כ עלות עגלה בחנות זו`}
                  description={cartComparisonExplain.hebrew.originalTotal}
                  price={calculateStoreTotalPrice(store)}
                />
                <Button onClick={() => handleSelectStore(store)}>Go To</Button>
              </h2>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CartComparisonAllCartsView;
