import { Language } from "../../../types/types";
import { PossibleErrorPageTypes } from "./errorPageHelper";

export const possibleErrors: Record<PossibleErrorPageTypes, Record<Language, string>> = {
  unknown: {
    hebrew: "שגיאה בלתי צפויה - נא צור קשר",
    english: "Sorry, an unexpected error has occurred",
    arabic: "عذرًا، حدث خطأ غير متوقع",
    russian: "Извините, произошла неожиданная ошибка",
  },
  serverDown: {
    hebrew: "השרת אינו זמין כעת. אנא נסה שוב מאוחר יותר.",
    english: "The server is currently unavailable. Please try again later.",
    arabic: "الخادم غير متاح حالياً. يرجى المحاولة لاحقاً.",
    russian: "Сервер временно недоступен. Пожалуйста, попробуйте позже.",
  },
  expiredLogin: {
    hebrew: "ההתחברות שלך פגה. אנא התחבר שוב.",
    english: "Your session has expired. Please log in again.",
    arabic: "انتهت جلستك. يرجى تسجيل الدخول مرة أخرى.",
    russian: "Ваша сессия истекла. Пожалуйста, войдите снова.",
  },
  expiredAction: {
    hebrew: "התוקף לפעולה זו פג , אנא נסה שנית.",
    english: "Your session is expired. Please try again.",
    arabic: "انتهت جلستك. يرجى المحاولة مرة أخرى.",
    russian: "Ваша сессия истекла. Пожалуйста, попробуйте снова.",
  },

  unauthorized: {
    hebrew: "אין לך הרשאות לגישה לדף זה.",
    english: "You do not have permission to access this page.",
    arabic: "ليس لديك إذن للوصول إلى هذه الصفحة.",
    russian: "У вас нет разрешения для доступа к этой странице.",
  },
  membersOnly: {
    hebrew: "תוכן זה זמין למשתמשים רשומים בלבד",
    english: "This content is only available to members.",
    arabic: "هذا المحتوى متاح فقط للأعضاء.",
    russian: "Этот контент доступен только для членов.",
  },
};
