import React, { useState, useReducer, Dispatch, SetStateAction, FC } from "react";
import serverAPI from "../../../api/server";
import InputUI from "../../UI/Input/Input";
import { Button } from "../../UI";
import "../Authentication.css";
import { EmailIcon, PasswordIcon } from "../../UI/Icons";
import { AuthenticationFormReducer, AuthenticationInitialState } from "../AuthReducer/AuthReducer";
import { dictAuth } from "../dictionary";

import { updateUserContextFromSnakeToCamelCase } from "../../../utils/utils";
import { AuthType } from "../Authentication";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import InfoBox, { InfoBoxType } from "../../UI/InfoBox/InfoBox";
import csrfToken from "../../../models/Tokens/CsrfToken";
import { useNavigate } from "react-router-dom";

const language = "hebrew";

type Props = {
  setAuthType?: Dispatch<SetStateAction<AuthType>>;
  handleClose: () => void;
  isFullPage?: boolean;
};

const Login: FC<Props> = ({ setAuthType, handleClose, isFullPage }) => {
  const { setUser } = useCustomContext();
  const navigate = useNavigate();
  const [togglePasswordVisible, setTogglePasswordVisible] = useState(false);
  const [formState, dispatch] = useReducer(AuthenticationFormReducer, AuthenticationInitialState);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<{ type: InfoBoxType; content: string }>({
    type: "gray",
    content: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await serverAPI("users/login", { email: formState.email, password: formState.password });
      if (res.statusCode === 200) {
        csrfToken.setToken(res.data.csrfToken);
        setResponseMessage({ type: "success", content: dictAuth.loginSuccess[language] });
        const user = updateUserContextFromSnakeToCamelCase(res.data.user);
        user.isUser = true;
        setUser(user);

        isFullPage && navigate("/");
        handleClose();
      } else {
        setResponseMessage({ content: dictAuth.userNotFound[language], type: "warning" });
      }
    } catch (error) {
      setResponseMessage({ type: "error", content: dictAuth.loginFailed[language] });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <InputUI
            id="email"
            htmlFor="email"
            name="SET_EMAIL"
            required
            autoComplete="current-email"
            type="email"
            language={language}
            label={dictAuth.email[language]}
            value={formState.email}
            onChange={handleInputChange}
            icon={<EmailIcon size={"50%"} color="var(--primary)" />}
          />
        </div>
        <div className="form-group">
          <InputUI
            id="password"
            name="SET_PASSWORD"
            htmlFor="password"
            required
            autoComplete="current-password"
            type={togglePasswordVisible ? "text" : "password"}
            language={language}
            label={dictAuth.password[language]}
            value={formState.password}
            onChange={handleInputChange}
            icon={
              <PasswordIcon onClick={() => setTogglePasswordVisible((p) => !p)} size={"55%"} color="var(--primary)" />
            }
          />
        </div>
        <div className="form-group">
          <Button htmlType="submit" loading={loading}>
            {dictAuth.login[language]}
          </Button>
        </div>

        {setAuthType && (
          <p className="redirect-to">
            <span onClick={() => setAuthType("register")}>{dictAuth.notAMember[language]}</span>
            <span onClick={() => setAuthType("forgetPassword")}>{dictAuth.forgotPassword[language]}</span>
          </p>
        )}
      </form>
      {responseMessage.content && (
        <InfoBox
          language={language}
          content={responseMessage.content}
          type={responseMessage.type}
          cssClass="response-message"
        />
      )}
    </div>
  );
};

export default Login;
