import LogoIcon from "../UI/Icons/Logo";

interface ILogoProps {
  fontSize: string | number;
  useResponsiveHiddenTextA?: boolean;
  useResponsiveHiddenTextB?: boolean;
  animate?: boolean;
  color?: string;
  cssClass?: string;
}
const Logo = ({
  fontSize,
  useResponsiveHiddenTextA,
  useResponsiveHiddenTextB,
  animate,
  color,
  cssClass = "",
}: // ,
ILogoProps) => {
  return (
    <div
      style={{
        fontSize: fontSize,
        direction: "rtl",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        margin: "auto",
      }}
      className={`${cssClass} ${animate ? "animation-fade-in-translateY animation-fade-in" : ""}`}
    >
      <LogoIcon size={"1em"} color={color} />
      <span className={`primary-color ${useResponsiveHiddenTextA ? "responsive-hidden" : ""} `}>תוזילו </span>
      <span
        className={`primary-color ${useResponsiveHiddenTextA || useResponsiveHiddenTextA ? "responsive-hidden" : ""}`}
      >
        &nbsp;
      </span>
      <span className={`primary-contrast-color  ${useResponsiveHiddenTextB ? "responsive-hidden" : ""}`}>מרקט </span>
    </div>
  );
};

export default Logo;
