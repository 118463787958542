import { useState, useReducer, useCallback, FC, Dispatch, SetStateAction, useEffect } from "react";
import { Button } from "../../UI";
import serverAPI from "../../../api/server";
import { AuthenticationFormReducer, AuthenticationInitialState } from "../AuthReducer/AuthReducer";
import "../Authentication.css";
import { dictAuth } from "../dictionary";
import PersonalData from "./tabs/PersonalData";
import { calculateStrength } from "./PasswordStrengthMeter/PasswordStrengthMeter";
import { isValidIsraelPhoneNumber, parseCookies } from "../../../utils/utils";
import { AuthType } from "../Authentication";
import { passwordStrengthValue } from "../../../utils/constants";
import { ErrorIcon, SuccessIcon } from "../../UI/Icons";
import { CookieName } from "../../../types/types";

const language = "hebrew";

type Props = {
  setAuthType: Dispatch<SetStateAction<AuthType>>;
  handleClose: () => void;
};

const Register: FC<Props> = ({ setAuthType, handleClose }) => {
  const [formState, dispatch] = useReducer(AuthenticationFormReducer, AuthenticationInitialState);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    bg: "pink",
    color: "red",
    text: "",
    icon: <ErrorIcon size="1em" />,
  });

  const handleRegister = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { confirmPassword, ...payload } = formState;

      const cookie = parseCookies(CookieName.Freeze);

      if (cookie) {
        setResponseMessage({
          bg: "none",
          color: "var(--font-color)",
          text: "please check in mail",
          icon: <></>,
        });
        return;
      }

      const res = await serverAPI("users/register", { ...payload, city: payload.city });
      if (res.statusCode === 201) {
        setResponseMessage({ bg: "#1ae027", color: "green", text: res.data.message, icon: <SuccessIcon size="1em" /> });
        // handleClose();
      } else if (res.statusCode === 409) {
        console.log(res);
        throw new Error(res.data);
      } else {
        throw new Error(res.data);
      }
    } catch (error: any) {
      setResponseMessage({
        bg: "pink",
        color: "red",
        text: `${error.message}`,
        icon: <ErrorIcon size="1em" />,
      });
    } finally {
      setLoading(false);
    }
  };

  const validatePersonalData = useCallback(() => {
    if (formState.phoneNumber && !isValidIsraelPhoneNumber(formState.phoneNumber)) return false;
    else if (calculateStrength(formState.password) < passwordStrengthValue) return false;
    else if (formState.password !== formState.confirmPassword) return false;
    return true;
  }, [formState]);

  useEffect(() => {
    if (responseMessage.text) {
      setResponseMessage({ bg: "pink", text: "", icon: <ErrorIcon size="1em" />, color: "red" });
    }
    // eslint-disable-next-line
  }, [formState]);

  return (
    <div className="auth-container">
      <div className="space-height-md" />
      <form onSubmit={handleRegister} style={{ width: "100%", height: "100%", minHeight: "500px" }}>
        <PersonalData formState={formState} language={language} dispatch={dispatch} />

        {responseMessage.text ? (
          <p className="response-message" style={{ color: responseMessage.color, backgroundColor: responseMessage.bg }}>
            <span>{responseMessage.icon}</span>
            <span>{responseMessage.text}</span>
          </p>
        ) : (
          <div className="space-height-md" />
        )}
        <div style={{ width: "50%", margin: "auto" }}>
          <div className="form-group">
            <Button htmlType="submit" loading={loading} disabled={!validatePersonalData()}>
              {dictAuth.register[language]}
            </Button>
          </div>
          <p className="redirect-to" onClick={() => setAuthType("login")}>
            {dictAuth.alreadyMember[language]}
          </p>
        </div>
      </form>
    </div>
  );
};

export default Register;
