import { useState, FC, Dispatch, SetStateAction, useCallback } from "react";
import { Card, Modal } from "../UI";
import { dictAuth } from "./dictionary";
import Register from "./Register/Register";
import Login from "./Login/Login";
import AlertMessage from "../UI/AlertMessage/AlertMessage";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";

type Prop = {
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  type?: AuthType;
  isFullPage?: boolean;
};

export type AuthType = "login" | "register" | "forgetPassword" | "resetPassword";

const Authentication: FC<Prop> = ({ isOpen, setIsOpen, type = "login", isFullPage }) => {
  const [authType, setAuthType] = useState<AuthType>(type);
  const { user } = useCustomContext();

  const handleClose = useCallback(() => {
    setIsOpen && setIsOpen(false);
  }, [setIsOpen]);

  const drawTitle = useCallback(() => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0 auto",
          direction: "rtl",
          color: "var(--secondary)",
        }}
      >
        <h2 style={{ color: "var(--primary)" }}>
          <span>{authType === "register" ? dictAuth.register.hebrew : dictAuth.login.hebrew}</span>
        </h2>
        <div>{dictAuth[(authType + "MeetingMessage") as "loginMeetingMessage" | "registerMeetingMessage"].hebrew}</div>
      </div>
    );
  }, [authType]);

  const drawBody = useCallback(() => {
    switch (authType) {
      case "login":
        return <Login isFullPage={isFullPage} setAuthType={setAuthType} handleClose={handleClose} />;
      case "register":
        return <Register setAuthType={setAuthType} handleClose={handleClose} />;
      case "forgetPassword":
        return <ForgetPassword setAuthType={setAuthType} handleClose={handleClose} />;
      default:
        return null;
    }
  }, [authType, handleClose, isFullPage]);

  if (!isOpen) return null;

  if (isFullPage) {
    return (
      <Card style={{ height: "100vh" }}>
        <Card title={drawTitle()} style={{ maxWidth: 800, margin: "10% auto", padding: "20px" }}>
          {drawBody()}
        </Card>
      </Card>
    );
  }

  return (
    <>
      <Modal
        maskClosable={authType === "login"}
        style={{ backgroundColor: "var(--white)" }}
        onCancel={handleClose}
        withFooter={false}
        visible={isOpen}
        margin={type === "register"}
        width={"fit-content"}
        dir="rtl"
        title={drawTitle()}
      >
        {drawBody()}
      </Modal>
      <AlertMessage message={user.isUser ? `Welcome ${user.fullName}` : ""} type="success" position="top" />
    </>
  );
};

export default Authentication;
