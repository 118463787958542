import { useState } from "react";
import { Typography } from "../../../components/UI";
import "./Tutorial.css";
import tutorial1 from "../../../assets/images/tutorial/desktopScreen-hp.png";
import AnimatedComponent from "../../../components/AnimatedComponent/AnimatedComponent";

const Tutorial = () => {
  const [isEnlargedImg, setIsEnlargedImg] = useState(false);

  const toggleEnlarge = () => {
    setIsEnlargedImg(!isEnlargedImg);
  };

  const drawCards = (startIndex: number, endIndex: number, cssClass: string) => {
    return TutorialCards.slice(startIndex, endIndex).map((c, i) => {
      return (
        <div
          key={i}
          className={`tutorial-card staggered-animation-3 staggered-animation animation-fade-in-${cssClass} animation-fade-in`}
        >
          <Typography.Title color="currentColor" level={2}>
            {c.title}
          </Typography.Title>
          <Typography.Paragraph>{c.text}</Typography.Paragraph>
        </div>
      );
    });
  };

  return (
    <AnimatedComponent
      animationClass="staggered-animation-3 animation-fade-in-translateY animation-fade-in"
      asContainer
    >
      <div className="hp-card flex-column flex-center flex-gap-20">
        <Typography.Title className="staggered-animation-4 animation-fade-in-translateY animation-fade-in">
          מה אנחנו מציעים?
        </Typography.Title>

        <div className="tutorial-body-wrapper">
          <div className="tutorial-body-left">{drawCards(0, 3, "translateX")}</div>
          <div
            className={`flex-center tutorial-img-wrapper ${
              isEnlargedImg ? "enlarged" : ""
            }  animation-fade-in-opacity animation-fade-in`}
          >
            <img
              onClick={toggleEnlarge}
              className={`tutorial-img ${isEnlargedImg ? "enlarged" : ""}`}
              src={tutorial1}
              alt="tutorial"
            />
          </div>
          <div className="tutorial-body-right">{drawCards(3, 6, "translateX-reverse")}</div>
        </div>
      </div>
    </AnimatedComponent>
  );
};

export default Tutorial;

const TutorialCards = [
  {
    title: "גרסת אתר",
    text: "ניתן לבחור בין גרסאות תצוגה שונות , לשיפור חווית השימוש בהתאמה אישית.",
    img: tutorial1,
  },
  {
    title: "בחירת חנויות",
    text: "אתם בוחרים את החנויות עבורם תרצו לבצע השוואה , ההמלצה שלנו היא עד 6 חנויות.",
    img: tutorial1,
  },
  { title: "מיון עגלה", text: "מיינו את העגלה שלכם לצורך שיפור חווית הקנייה.", img: tutorial1 },
  {
    title: "קנייה מהירה",
    text: "ניתן ליצור רשימת קניות בטקסט חופשי , אנו נביא לכם את המוצרים מרוכזים במקום אחד.",
    img: tutorial1,
  },
  {
    title: "השוואת מחירים",
    text: "מיד בסיום מילוי העגלה , נעבור לאיזור השוואת המחירים, פה נוכל להשוות בין מה שבחרנו לחנויות האחרות בצורה נוחה ומתומצתת.",
    img: tutorial1,
  },
  {
    title: "החלפת פריטים",
    text: "לא תמיד נוכל למצוא את הפריט המבוקש , ייתכן וחסר במלאי , או תיאור המוצר שונה בין החנויות , לכן אנו מאפשרים לכם בזמן ההשוואה , לבצע חיפוש חופשי לפריטים חסרים.",
    img: tutorial1,
  },
];
