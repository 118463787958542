import { useState, useRef } from "react";
import { Modal } from "../../../../UI";
import "./ShoppingList.css";
import { ListIcon, TrashIcon } from "../../../../UI/Icons";
import { useNavigate } from "react-router-dom";
import { SiteNavOptionsTitle } from "../SiteNavOptions";
import useCustomContext from "../../../../../hooks/useCustomContext/useCustomContext";
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import TempComponentHebrewOnlyInfo from "../../../../ErrorPage/HebrewOnlyInfo";

const ShoppingList = () => {
  const { isShoppingListOpen, setIsShoppingListOpen } = useCustomContext();

  const [productsInput, setProductsInputs] = useState(["", ""]);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const navigate = useNavigate();

  const handleInputChange = (index: number, value: string) => {
    const newProductsInputs = [...productsInput];
    newProductsInputs[index] = value;
    setProductsInputs(newProductsInputs);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === "Enter") {
      // Prevent the default Enter key behavior (e.g., form submission)
      event.preventDefault();

      if (inputRefs.current[index].value.length <= 1) return;
      if (index !== 0 && inputRefs.current[index - 1].value.length < 1) {
        const newProductsInputs = [...productsInput];
        newProductsInputs.splice(index - 1, 1);
        setProductsInputs(newProductsInputs);
      }

      if (index !== 0 && inputRefs.current[index - 1].value.length <= 1) return;

      setProductsInputs((prev) => [...prev, ""]);

      // Move focus to the new input using ref
      const nextIndex = index + 1;
      inputRefs.current[nextIndex]?.focus();
    }

    if (event.key === "Backspace") {
      // if (inputRefs?.current[index].selectionEnd || (inputs[index].length === 0 && index !== 0)) {
      if (productsInput[index].length === 0 && index !== 0) {
        setProductsInputs((prev) => {
          const newProductsInputs = [...prev];

          newProductsInputs.splice(index, 1);
          return newProductsInputs;
        });
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const drawInputs = () => {
    const placeholderA = "נא להקליד מוצר לשורה ללא כמויות";
    const placeholderB = "למעבר לשורה הבא לחצו על Enter";

    return productsInput.map((v, idx) => {
      return (
        <p key={idx}>
          {productsInput[idx] && <span>{idx + 1}.</span>}
          <input
            onChange={(e) => handleInputChange(idx, e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, idx)}
            type="text"
            value={v}
            id={`shopping-list-item-${idx}`}
            ref={(el) => (inputRefs.current[idx] = el!)}
            placeholder={idx === 0 ? placeholderA : idx === 1 ? placeholderB : ""}
            tabIndex={idx}
            autoComplete="off"
          />
        </p>
      );
    });
  };

  const drawTitle = () => {
    return (
      <div className="sl-title-wrapper">
        <h1 className="sl-title">רשימת קניות</h1>
        {productsInput.length > 1 && productsInput[0] && productsInput[1] && (
          <div className="sl-clean-all" onClick={() => setProductsInputs(["", ""])}>
            <TrashIcon size={"2rem"} color="var(--primary)" />
          </div>
        )}
      </div>
    );
  };

  const handleOk = async () => {
    setIsShoppingListOpen(false);
    navigate("/products/list", { state: { inputs: productsInput } });
  };

  const handleCancle = () => {
    setProductsInputs(["", ""]);
    setIsShoppingListOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsShoppingListOpen((p) => !p)}>
        <Dropdown
          items={[]}
          title={<SiteNavOptionsTitle Icon={ListIcon} label={<div className="flex-center">רשימת קניות</div>} />}
        />
      </div>

      {isShoppingListOpen && (
        <Modal
          // TODO: SHOPPING LIST IS ONLY HEBREW FOR NOW
          // dir={resolveDirClass(language)}
          dir={"rtl"}
          visible={isShoppingListOpen}
          title={drawTitle()}
          onOk={handleOk}
          onCancel={handleCancle}
          includeCloseBtn={false}
        >
          <TempComponentHebrewOnlyInfo type="modal" source="ShoppingList" />
          <div id="shopping-list-container">
            <div id="shopping-list-content">
              <div className="hipsum">{drawInputs()}</div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ShoppingList;
