import "./SkeletonCard.css";

interface ISkeletonCardProps {
  width?: string; // card width (e.g., '200px', '100%')
  height?: string; // card height (e.g., '300px', '100%')
  animatedElements?: AnimatedElement[]; // array of animations to show
  borderRadius?: string; // border radius for card
  cssClass?: string;
}

interface AnimatedElement {
  width: string; // width of the animated element
  height: string; // height of the animated element
  type?: "circle" | "rect"; // shape of the skeleton (e.g., circle for avatar or rect for text)
  top?: string; // top position within the card
  left?: string; // left position within the card
  borderRadius?: string; // border-radius for the shape
}

const SkeletonCard: React.FC<ISkeletonCardProps> = ({
  width,
  height,
  animatedElements = [],
  borderRadius = "10px",
  cssClass,
}) => {
  return (
    <div className={`skeleton-card ${cssClass}`} style={{ width, height, borderRadius }}>
      {animatedElements.map((el, index) => (
        <div
          key={index}
          className={`skeleton-${el.type || "rect"}`}
          style={{
            width: el.width,
            height: el.height,
            top: el.top || "0",
            left: el.left || "0",
            borderRadius: el.borderRadius || (el.type === "circle" ? "50%" : "4px"),
          }}
        />
      ))}
    </div>
  );
};

export default SkeletonCard;
