import React, { ReactNode } from "react";
import { Flex } from "../../UI";

interface IProps {
  icon: ReactNode;
  content: string;
  onClick: () => void;
}

const AccessabilityActionBox = ({ content, icon, onClick }: IProps) => {
  return (
    <Flex
      cssClass="accessability-action-box"
      onClick={onClick}
      gap={5}
      flexDirection="column"
      justify="center"
      align="center"
    >
      <span>{icon}</span>
      <span>{content}</span>
    </Flex>
  );
};

export default AccessabilityActionBox;
