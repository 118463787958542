import React, { useState, SetStateAction, Dispatch, FC } from "react";
import { Cart, StoreEn } from "../types/types";
import useCustomContext from "../hooks/useCustomContext/useCustomContext";
import "./test.css";
import PriceCalculator from "../models/ItemPriceCalculator/PriceCalculator";
import { getNormalizingStorePrice } from "../pages/CartComparisonPage/Helpers/Utils";
import drawCartItems from "../components/Cart/CartContent/helper/drawCartItems";
import { SortCartByCategories } from "../components/Cart/CartContent/helper/SortCartByCategories";
import CartHeader from "../components/Cart/CartHeader/CartHeader";
import useLocalStorage, { localStorageKeys } from "../hooks/useLocalStorage";
import { cartSortingMethods } from "../components/Cart/CartContent/CartContent";

import Authentication from "../components/Authentication/Authentication";

// TODOS:
// 1. consider to add search in cart , sorting etc
// 2. if sorting posiible try implement scroll
// 4. fix header on all carts view
// 3. try implement scroll on carousel view

const TestComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  if (1 > 0) {
    return <Authentication type="login" isOpen={isOpen} setIsOpen={setIsOpen} isFullPage />;
  }
  return <DrawMultiCarts />;
};

export default TestComponent;

const DrawMultiCarts: FC = () => {
  const {
    cart,
    onlineStores: { favoriteStore, otherStores },
  } = useCustomContext();
  const [scrollTopAll, setScrollTopAll] = useState(0);
  const [sortingMethod, setSortingMethod] = useLocalStorage(localStorageKeys.cartSortingMethod, "abc");

  const itemsKeys = Object.keys(cart);
  const stores = [favoriteStore, ...otherStores];

  const handleScroll = (singleScroll: number) => {
    if (scrollTopAll !== singleScroll) {
      setScrollTopAll(singleScroll);
    }
  };

  return (
    <div className="test_wrapper">
      {stores.map((store) => {
        return (
          <DrawCart
            key={store}
            cart={cart}
            favoriteStore={favoriteStore}
            store={store}
            itemsKeys={itemsKeys}
            onScroll={handleScroll}
            scrollTop={scrollTopAll}
            sortingMethod={sortingMethod}
            setSortingMethod={store === favoriteStore ? setSortingMethod : undefined}
          />
        );
      })}
    </div>
  );
};

interface IDrawCart {
  store: StoreEn;
  cart: Cart;
  itemsKeys: string[];
  favoriteStore: StoreEn;
  onScroll: (scrollTopSingle: number) => void;
  scrollTop: number;
  sortingMethod?: cartSortingMethods;
  setSortingMethod?: Dispatch<SetStateAction<cartSortingMethods>>;
}
const DrawCart = ({
  store,
  cart,
  favoriteStore,
  itemsKeys,
  onScroll,
  scrollTop,
  setSortingMethod,
  sortingMethod,
}: IDrawCart) => {
  const drawSortedCart = () => {
    switch (sortingMethod) {
      case "order":
      case "abc":
        if (sortingMethod === "order") itemsKeys.reverse();
        return drawCartItems(itemsKeys, cart, store, {
          featureName: "comparison_cart",
          itemSize: 80,
          visibleItems: 6,
          scrollTop,
          onScroll,
        });
      case "categories":
        return (
          <SortCartByCategories
            cart={cart}
            language="hebrew"
            store={store}
            featureName="comparison_cart"
            favoriteStore={favoriteStore}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="test_column">
      <div className="test_column_box">
        <CartHeader
          setSortingMethod={setSortingMethod}
          sortingMethod={sortingMethod}
          store={store}
          onClose={() => console.log("clicked on cart close btn")}
        />
      </div>
      <div className="test_column_box test_columns_cart_content">{drawSortedCart()}</div>
      <div className="test_column_box">
        <div>Total price {PriceCalculator.getTotalCartCost(store, cart)}</div>
        {store !== favoriteStore && (
          <div>Favorite Relative price {getNormalizingStorePrice(store, favoriteStore, cart, "string")}</div>
        )}
      </div>
    </div>
  );
};
