import "./HomePageFooter.css";
import { Typography } from "../../UI";
import { EmailIcon, FacebookIcon, InstagramIcon, LinkedinIcon, LocationIcon, PhoneIcon } from "../../UI/Icons";
import { Link, useNavigate } from "react-router-dom";
import { memo } from "react";

interface IProps {
  bottom?: boolean;
  top?: boolean;
}

const HomePageFooter = ({ bottom = true, top = true }: IProps) => {
  const navigate = useNavigate();
  return (
    <footer id="hp-footer">
      <div className="hp-footer-container">
        {top && (
          <div className="hp-footer-section">
            <div className="hp-main-footer-card">
              <Typography.Title strong color="var(--primary-contrast-color)">
                Tozilu Market
              </Typography.Title>
              <Typography.Paragraph>
                Price Comparison,Send money instantly to anyone & pay your bills just in 3 steps with Grocery.{" "}
              </Typography.Paragraph>
            </div>
            <div className="hp-main-footer-card">
              <Typography.Title level={3} strong color="var(--white)">
                Navigation
              </Typography.Title>
              <Typography.Text className="hover-contrast">
                <Link to="/">Home</Link>
              </Typography.Text>
              <Typography.Text className="hover-contrast">
                <Link to="/legal/privacyPolicy">Privacy Policy</Link>
              </Typography.Text>
              <Typography.Text className="hover-contrast">
                <Link to="/legal/termsOfUse">Terms & Conditions</Link>{" "}
              </Typography.Text>
              <Typography.Text className="hover-contrast">User Profile</Typography.Text>
            </div>
            <div className="hp-main-footer-card">
              <Typography.Title level={3} strong color="var(--white)">
                Contacts
              </Typography.Title>
              <Typography.Text className="flex-align flex-gap-10">
                <LocationIcon color="var(--white)" size="20px" />
                <p>Not real address , Israel</p>
              </Typography.Text>
              <Typography.Text className="flex-align flex-gap-10">
                <PhoneIcon color="var(--white)" size="20px" />
                <p>051-2345678</p>
              </Typography.Text>
              <Typography.Text onClick={() => navigate("/contact")} className="hover-contrast flex-align flex-gap-10">
                <EmailIcon color="var(--white)" size="20px" />
                <p>info@tozilu.com</p>
              </Typography.Text>
            </div>
          </div>
        )}
        {bottom && (
          <div className="hp-footer-section hp-footer-bottom">
            <Typography.Text>© 2024 Tozilu. All rights reserved.</Typography.Text>
            <div className="flex-center flex-gap-10">
              <div className="hp-footer-icon-wrapper flex-center">
                <FacebookIcon />
              </div>
              <div className="hp-footer-icon-wrapper flex-center">
                <LinkedinIcon />
              </div>
              <div className="hp-footer-icon-wrapper flex-center">
                <InstagramIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default memo(HomePageFooter);
