import { useEffect, useState } from "react";
import "./CartComparisonCarouselView.css";
import { Button, CarouselFull } from "../../../../components/UI";
import { dictStores } from "../../../../dictionary/dictStores";
import { StoreEn } from "../../../../types/types";
import { getMissingItems, getNormalizingStorePrice } from "../../Helpers/Utils";
import { cartComparisonExplain } from "../../Helpers/dict";
import { DrawFooterBox } from "../../Helpers/Ui";
import useCustomContext from "../../../../hooks/useCustomContext/useCustomContext";
import PriceCalculator from "../../../../models/ItemPriceCalculator/PriceCalculator";
import drawCartItems from "../../../../components/Cart/CartContent/helper/drawCartItems";

interface ICartComparisonCarouselViewProps {
  handleSelectStore: (store: StoreEn) => void;
}

const CartComparisonCarouselView = ({ handleSelectStore }: ICartComparisonCarouselViewProps) => {
  const {
    cart,
    user: { language },
    onlineStores: { favoriteStore, otherStores },
  } = useCustomContext();

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [missingItems, setMissingItems] = useState<string[]>([]);
  const cartItems = Object.keys(cart);

  const calculateStoreTotalPrice = (store: StoreEn) => {
    return PriceCalculator.getTotalCartCost(store, cart);
  };

  // Normalizing the favorite cart per founded items only.
  const calculateFavoriteFakeCartAccordingCurrentStore = (normalizingStore: StoreEn) => {
    // removing products that not exist in normalizingStore
    return getNormalizingStorePrice(normalizingStore, favoriteStore, cart, "string");
  };

  useEffect(() => {
    setMissingItems(getMissingItems(cart, favoriteStore, otherStores[carouselIndex]));
  }, [carouselIndex, cart, favoriteStore, otherStores]);

  return (
    <div
      onScroll={(e) => console.log(e.currentTarget.scrollTop)}
      className="cart-comparison_carouselView_wrapper scrollable"
    >
      <div className="cart-comparison_cart_wrapper">
        <div style={{ flex: 1, height: "100%", overflow: "auto", border: "1px solid red" }}>
          <h2 className="cart-comparison_cart_header">{dictStores[favoriteStore][language]}</h2>
          {drawCartItems(cartItems, cart, favoriteStore, { itemSize: 80 })}
        </div>
        <h2 className="cart-comparison_cart_footer">
          <DrawFooterBox
            toolTipTitle={`סה"כ עלות העגלה שלי שמילאתי לפי ${dictStores[favoriteStore][language]}`}
            description={cartComparisonExplain.hebrew.originalTotal}
            price={calculateStoreTotalPrice(favoriteStore).toFixed(2)}
          />
          {!missingItems?.length ? null : (
            <DrawFooterBox
              toolTipTitle={cartComparisonExplain.hebrew.normalTotalFavoriteTooltip(
                favoriteStore,
                otherStores[carouselIndex]
              )}
              description={cartComparisonExplain.hebrew.normalTotal}
              price={calculateFavoriteFakeCartAccordingCurrentStore(otherStores[carouselIndex]) as string}
            />
          )}

          <Button onClick={() => handleSelectStore(favoriteStore)}>Go To</Button>
        </h2>
      </div>
      <div onScroll={(e) => console.log(e.currentTarget.scrollTop)} className="cart-comparison_cart_wrapper">
        <h2 className="cart-comparison_cart_header">{dictStores[otherStores[carouselIndex]][language]}</h2>
        <CarouselFull
          arrowsCss="cart-comparison-carousel-custom-fixed-btn"
          infinite
          onSliding={(s) => setCarouselIndex(s)}
          containerStyle={{ flex: 1 }}
        >
          {otherStores.map((s) => (
            <div style={{ border: "1PX SOLID BLACK", height: "100%", overflowY: "auto" }} key={s}>
              {drawCartItems(cartItems, cart, otherStores[carouselIndex])}
            </div>
          ))}
        </CarouselFull>
        <h2 className="cart-comparison_cart_footer">
          {!missingItems.length ? null : (
            <DrawFooterBox
              toolTipTitle={`פריטים שחסרים בחנות זו :\n ${missingItems.join(", \n")}`}
              description={`חסרים ${missingItems.length} פריטים`}
            />
          )}
          <DrawFooterBox
            toolTipTitle="explain about real price "
            description={cartComparisonExplain.hebrew.originalTotal}
            price={calculateStoreTotalPrice(otherStores[carouselIndex]).toFixed(2)}
          />
          <Button onClick={() => handleSelectStore(otherStores[carouselIndex])}>Go To</Button>
        </h2>
      </div>
    </div>
  );
};

export default CartComparisonCarouselView;
