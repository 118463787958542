import { LegalTerms } from "../LegalPolicy";

const date_placeholder = "[30/09/2024]";
const email_placeholder = "tozilu@gmail.com";
const privacyPolicyDataV1: LegalTerms = {
  hebrew: {
    title: 'מדיניות פרטיות לאתר "תוזילו"',
    lastUpdated: `תאריך עדכון אחרון: ${date_placeholder}`,
    sections: [
      {
        index: 1,
        title: "איזה מידע אנחנו אוספים?",
        content: [
          "אנו אוספים מידע אישי מהמשתמשים למטרות שונות, כולל:",
          "- שם מלא: לצורכי זיהוי בעת השימוש באתר.",
          "- עיר מגורים: לצורך סינון חנויות רלוונטיות.",
          "- אימייל: לצורכי יצירת קשר, שליחת עדכונים ואימותים.",
          "- מספר סלולרי: לצורכי יצירת קשר ואימותים עתידיים.",
          "- שפה מועדפת: לצורך התאמה אישית של חוויית השימוש.",
          "- סיסמה: נשמרת בפורמט מוצפן בלבד, ומשמשת לאימות הגישה למשתמש.",
        ],
      },
      {
        index: 2,
        title: "שימוש בעוגיות (Cookies)",
        content: [
          "אנו משתמשים בקוקיז לצורך שיפור חוויית המשתמש ולתפעול השוטף של האתר:",
          "- אחסון טוקן חיבור: אנו שומרים בעוגיות טוקן חיבור המכיל פרטי גישה לא סודיים.",
          "- אחסון מצב עגלה: בכדי לאפשר למשתמשים להמשיך מהמקום שבו עצרו במידה ונסגר הדפדפן.",
          "- מניעת בקשות מרובות: אנו מאחסנים עוגיות זמניות במקרים של יצירת קשר כדי למנוע הצפה במיילים.",
        ],
      },
      {
        index: 3,
        title: "אחסון מידע בצד הלקוח",
        content: [
          "אנחנו מאחסנים את הפרטים הבאים בדפדפן המשתמש (Local Storage):",
          "- מצב החנויות הנבחרות להשוואה: כדי לאפשר השוואת מחירים מהירה יותר ולשפר את חוויית המשתמש.",
        ],
      },
      {
        index: 4,
        title: "שימוש במידע",
        content: [
          "המידע האישי שנאסף משמש לצרכים הבאים בלבד:",
          "- זיהוי ואימות משתמש: שימוש בשם, אימייל וסיסמה לאימות המשתמשים במערכת.",
          "- יצירת קשר ועדכונים: נשתמש באימייל ובמספר הטלפון שלכם בכדי לעדכן אתכם לגבי שינויים בשירות, מוצרים, ומבצעים.",
          "- אחסון מידע צרכני: אנו עשויים לאסוף מידע סטטיסטי על הרגלי צריכה של לקוחות, מבלי לכלול פרטים אישיים, לצורך מחקר ושיפור השירותים.",
        ],
      },
      {
        index: 5,
        title: "שיתוף מידע",
        content: [
          "- ללא שיתוף פרטים אישיים לצד שלישי: אנו לא נשתף מידע אישי שלכם עם צדדים שלישיים, למעט במקרים בהם נדרש על פי חוק.",
          "- שיתוף מידע סטטיסטי: ייתכן שנשתף מידע אנונימי או סטטיסטי עם צדדים שלישיים, אך ללא פרטים אישיים מזהים.",
        ],
      },
      {
        index: 6,
        title: "אבטחת מידע",
        content: [
          "אנו משתמשים באמצעי אבטחה מתקדמים, כולל הצפנת סיסמאות באמצעות bcrypt, בכדי להגן על המידע האישי שלכם.",
          "האתר מאובטח באמצעות HTTPS.",
        ],
      },
      {
        index: 7,
        title: "פרסומות ועדכונים",
        content: ["בהתאם להסכמת המשתמש, אנו עשויים לשלוח פרסומות או הודעות בנוגע למוצרים ושירותים מותאמים אישית."],
      },
      {
        index: 8,
        title: "יצירת קשר",
        content: [`לכל שאלה או פנייה בנוגע למדיניות הפרטיות, ניתן לפנות אלינו באמצעות דוא"ל ${email_placeholder}.`],
      },
    ],
  },
  english: {
    title: 'Privacy Policy for "Tozilu"',
    lastUpdated: `Last updated: ${date_placeholder}`,
    sections: [
      {
        index: 1,
        title: "What Information Do We Collect?",
        content: [
          "We collect personal information from users for various purposes, including:",
          "- Full Name: For identification purposes during the use of the website.",
          "- City: To filter irrelevant stores.",
          "- Email: For contact purposes, updates, and verifications.",
          "- Phone Number: For contact purposes and potential future verifications.",
          "- Preferred Language: To personalize the user experience.",
          "- Password: Stored in encrypted format only, used for user authentication.",
        ],
      },
      {
        index: 2,
        title: "Use of Cookies",
        content: [
          "We use cookies to improve the user experience and for the website’s operation:",
          "- Token Storage: We store a connection token in cookies containing non-sensitive login information.",
          "- Cart State Storage: To allow users to resume from where they left off if the browser is closed.",
          "- Preventing Multiple Requests: We store temporary cookies, such as for contact form submissions, to prevent spamming through multiple emails.",
        ],
      },
      {
        index: 3,
        title: "Client-Side Information Storage",
        content: [
          "We store the following information in the user’s browser (Local Storage):",
          "- Selected stores for comparison: To allow faster price comparisons and improve user experience.",
        ],
      },
      {
        index: 4,
        title: "Use of Information",
        content: [
          "The personal information we collect is used solely for the following purposes:",
          "- User identification and authentication: We use name, email, and password for user authentication.",
          "- Contact and Updates: We will use your email and phone number to update you about service changes, products, and promotions.",
          "- Consumer data storage: We may collect statistical data on customer consumption habits, excluding personal details, for research and service improvement.",
        ],
      },
      {
        index: 5,
        title: "Sharing Information",
        content: [
          "- No sharing of personal details with third parties: We do not share your personal information with third parties, except where required by law.",
          "- Sharing of statistical information: We may share anonymous or statistical information with third parties, without including any personally identifiable details.",
        ],
      },
      {
        index: 6,
        title: "Data Security",
        content: [
          "We use advanced security measures, including password encryption via bcrypt, to protect your personal information.",
          "The site is secured via HTTPS.",
        ],
      },
      {
        index: 7,
        title: "Advertisements and Updates",
        content: [
          "With the user’s consent, we may send advertisements or messages about personalized products and services.",
        ],
      },
      {
        index: 8,
        title: "Contact Us",
        content: [
          `For any questions or inquiries regarding this Privacy Policy, you can reach us via email at ${email_placeholder}.`,
        ],
      },
    ],
  },
  arabic: {
    title: 'سياسة الخصوصية لموقع "توزيلو"',
    lastUpdated: `آخر تحديث: ${date_placeholder}`,
    sections: [
      {
        index: 1,
        title: "ما هي المعلومات التي نجمعها؟",
        content: [
          "نحن نجمع معلومات شخصية من المستخدمين لأغراض متعددة، بما في ذلك:",
          "- الاسم الكامل: لأغراض التعريف أثناء استخدام الموقع.",
          "- المدينة: لتصفية المتاجر غير ذات الصلة.",
          "- البريد الإلكتروني: لأغراض الاتصال، التحديثات، والتحقق.",
          "- رقم الهاتف: لأغراض الاتصال وقد يتم استخدامه في المستقبل لأغراض التحقق.",
          "- اللغة المفضلة: لتخصيص تجربة المستخدم.",
          "- كلمة المرور: يتم تخزينها في شكل مشفر فقط، وتستخدم للمصادقة.",
        ],
      },
      {
        index: 2,
        title: "استخدام ملفات تعريف الارتباط (الكوكيز)",
        content: [
          "نحن نستخدم الكوكيز لتحسين تجربة المستخدم ولتشغيل الموقع:",
          "- تخزين التوكين: نقوم بتخزين توكين الاتصال في ملفات تعريف الارتباط التي تحتوي على معلومات تسجيل الدخول غير السرية.",
          "- تخزين حالة السلة: لتمكين المستخدمين من متابعة ما كانوا يفعلونه إذا أغلقوا المتصفح.",
          "- منع الطلبات المتكررة: نقوم بتخزين ملفات تعريف الارتباط المؤقتة مثل إرسال النماذج لمنع تكرار الطلبات بشكل مفرط.",
        ],
      },
      {
        index: 3,
        title: "تخزين المعلومات على الجانب العميل",
        content: [
          "نحن نخزن المعلومات التالية في متصفح المستخدم (التخزين المحلي):",
          "- المتاجر المختارة للمقارنة: للسماح بمقارنات الأسعار بشكل أسرع وتحسين تجربة المستخدم.",
        ],
      },
      {
        index: 4,
        title: "استخدام المعلومات",
        content: [
          "يتم استخدام المعلومات الشخصية التي نجمعها للأغراض التالية فقط:",
          "- التعريف والمصادقة: نستخدم الاسم، البريد الإلكتروني، وكلمة المرور للتحقق من هوية المستخدم.",
          "- الاتصال والتحديثات: سنستخدم بريدك الإلكتروني ورقم هاتفك لتحديثك حول التغييرات في الخدمة والمنتجات والعروض.",
          "- تخزين بيانات المستهلك: قد نجمع بيانات إحصائية عن عادات استهلاك العملاء، بدون تفاصيل شخصية، لأغراض البحث وتحسين الخدمة.",
        ],
      },
      {
        index: 5,
        title: "مشاركة المعلومات",
        content: [
          "- لا يتم مشاركة التفاصيل الشخصية مع أطراف ثالثة: نحن لا نشارك معلوماتك الشخصية مع أطراف ثالثة إلا إذا كان ذلك مطلوباً قانونياً.",
          "- مشاركة المعلومات الإحصائية: قد نشارك المعلومات الإحصائية أو المجهولة مع أطراف ثالثة بدون تضمين أي تفاصيل تحدد هوية المستخدم.",
        ],
      },
      {
        index: 6,
        title: "أمن البيانات",
        content: [
          "نحن نستخدم إجراءات أمان متقدمة، بما في ذلك تشفير كلمة المرور عبر bcrypt، لحماية معلوماتك الشخصية.",
          "الموقع مؤمن باستخدام HTTPS.",
        ],
      },
      {
        index: 7,
        title: "الإعلانات والتحديثات",
        content: ["بموافقة المستخدم، قد نرسل إعلانات أو رسائل حول المنتجات والخدمات المخصصة."],
      },
      {
        index: 8,
        title: "اتصل بنا",
        content: [
          `لأي استفسارات أو تساؤلات بخصوص سياسة الخصوصية، يمكنك الاتصال بنا عبر البريد الإلكتروني ${email_placeholder}.`,
        ],
      },
    ],
  },
  russian: {
    title: 'Политика конфиденциальности сайта "Тузилу"',
    lastUpdated: `Последнее обновление: ${date_placeholder}`,
    sections: [
      {
        index: 1,
        title: "Какие данные мы собираем?",
        content: [
          "Мы собираем личные данные пользователей для различных целей, включая:",
          "- Полное имя: для идентификации при использовании сайта.",
          "- Город: для фильтрации нерелевантных магазинов.",
          "- Электронная почта: для связи, обновлений и подтверждения.",
          "- Номер телефона: для связи, в будущем возможно для аутентификации.",
          "- Предпочитаемый язык: для персонализации пользовательского интерфейса.",
          "- Пароль: хранится в зашифрованном виде и используется для аутентификации.",
        ],
      },
      {
        index: 2,
        title: "Использование файлов cookie",
        content: [
          "Мы используем файлы cookie для улучшения пользовательского опыта и обеспечения работы сайта:",
          "- Хранение токена: мы сохраняем токен авторизации в cookie, содержащем неконфиденциальные данные пользователя.",
          "- Сохранение состояния корзины: для того, чтобы пользователь мог продолжить работу, если закрыл браузер.",
          "- Предотвращение повторных запросов: временные файлы cookie могут использоваться для предотвращения многократных запросов, например, при отправке формы.",
        ],
      },
      {
        index: 3,
        title: "Хранение данных на стороне клиента",
        content: [
          "Мы сохраняем следующие данные в локальном хранилище браузера пользователя:",
          "- Выбранные магазины для сравнения: для более быстрого доступа к информации и улучшения пользовательского опыта.",
        ],
      },
      {
        index: 4,
        title: "Использование информации",
        content: [
          "Личные данные, которые мы собираем, используются исключительно для следующих целей:",
          "- Идентификация и аутентификация: имя, электронная почта и пароль используются для подтверждения личности пользователя.",
          "- Связь и обновления: мы будем использовать вашу электронную почту и номер телефона для отправки обновлений и информации о сервисе и продуктах.",
          "- Сбор потребительских данных: мы можем собирать статистические данные о потребительских предпочтениях клиентов без включения личной информации, для улучшения сервиса.",
        ],
      },
      {
        index: 5,
        title: "Передача данных третьим лицам",
        content: [
          "- Личные данные не передаются третьим лицам: мы не делимся вашими личными данными с третьими лицами, за исключением случаев, предусмотренных законодательством.",
          "- Передача статистических данных: мы можем делиться анонимной или обобщённой информацией с третьими сторонами, не раскрывая личные данные пользователя.",
        ],
      },
      {
        index: 6,
        title: "Безопасность данных",
        content: [
          "Мы используем современные методы защиты данных, включая шифрование паролей с использованием bcrypt, для обеспечения безопасности вашей личной информации.",
          "Сайт защищен с использованием HTTPS.",
        ],
      },
      {
        index: 7,
        title: "Реклама и обновления",
        content: [
          "С вашего согласия мы можем отправлять рекламные материалы или уведомления о персонализированных продуктах и услугах.",
        ],
      },
      {
        index: 8,
        title: "Свяжитесь с нами",
        content: [
          `Если у вас есть вопросы или предложения по поводу политики конфиденциальности, вы можете связаться с нами по электронной почте ${email_placeholder}.`,
        ],
      },
    ],
  },
};

export default privacyPolicyDataV1;
