import { Dispatch, SetStateAction, FC, useState, useEffect } from "react";
import { User } from "../../../../../types/types";
import { UserIcon } from "../../../../UI/Icons";
import Authentication, { AuthType } from "../../../../Authentication/Authentication";
import serverAPI from "../../../../../api/server";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import { SiteNavOptionsTitle } from "../SiteNavOptions";

type Props = {
  setUser: Dispatch<SetStateAction<User>>;
  fullName: string;
  isUser: boolean;
  cartLength: number;
};

const logoutUserObj = (prev: User) => {
  return {
    ...prev,
    isUser: false,
    fullName: "אורח",
    email: "Guest",
    phoneNumber: undefined,
    city: null,
  };
};

export const logoutUser = async (setUser: Dispatch<SetStateAction<User>>) => {
  await serverAPI("users/logout", {});
  setUser((prev) => logoutUserObj(prev));
};

const UserAuthSelect: FC<Props> = ({ setUser, fullName, isUser, cartLength }) => {
  const [openAuthentication, setOpenAuthentication] = useState(false);
  const [authenticationType, setAuthenticationType] = useState<AuthType>("login");
  const navigate = useNavigate();
  const location = useLocation();
  const handleAuthClick = (type: "login" | "register") => {
    if (!isUser) {
      setAuthenticationType(() => type);
      setOpenAuthentication(true);
    } else logoutUser(setUser);
  };

  useEffect(() => {
    if (!isUser && location.pathname.startsWith("/products") && cartLength % 5 === 0) {
      setOpenAuthentication(true);
    }
    // eslint-disable-next-line
  }, [cartLength, isUser]);

  const items = [
    <div onClick={() => handleAuthClick("login")}>{isUser ? "התנתק" : "התחברות"}</div>,
    !isUser ? <div onClick={() => handleAuthClick("register")}>הרשמה</div> : null,
    isUser ? <div onClick={() => navigate("/profile/login")}>פרופיל</div> : null,
  ];

  return (
    <>
      <Dropdown items={items} title={<SiteNavOptionsTitle Icon={UserIcon} label={fullName} />} />
      {openAuthentication && (
        <Authentication isOpen={openAuthentication} setIsOpen={setOpenAuthentication} type={authenticationType} />
      )}
    </>
  );
};

export default UserAuthSelect;
