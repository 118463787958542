import { FC } from "react";
import { Segmented, Tooltip } from "../../../components/UI";

interface IProps {
  handleSegmentChange: (option: { key: string; label: string }) => void;
  defaultSegment?: string;
}

const segmentOptions = [
  { label: "עגלה מול עגלה", key: "carousel_cart" },
  { key: "single_item", label: "פריטים בנפרד" },
  // { label: "טבלה", key: "table" },
  { label: "כל העגלות", key: "all_carts" },
  { label: "test", key: "test" },
  { label: "test2", key: "test2" },
];

const CartComparisonHeader: FC<IProps> = ({ handleSegmentChange, defaultSegment }) => {
  return (
    <div className="comparison-page-header responsive-hidden">
      <span>
        <Tooltip placement="bottom" title="לנוחיות המשתמש בלבד ,ניתן לבצע את אותן פעולות בתצוגה שונה." />
      </span>
      <span>
        <b>בחירת מצב תצוגה:</b>
      </span>
      <Segmented
        defaultKey={window.innerWidth <= 576 ? "single_item" : defaultSegment}
        options={segmentOptions}
        onChange={handleSegmentChange}
      />
    </div>
  );
};

export default CartComparisonHeader;
