import { LegalTerms } from "../LegalPolicy";

const date_placeholder = "[30/09/2024]";
const email_placeholder = "tozilu@gmail.com";
const accessibilityStatementData: LegalTerms = {
  hebrew: {
    title: "הצהרת נגישות",
    lastUpdated: `תאריך עדכון אחרון: ${date_placeholder}`,
    sections: [
      {
        index: 1,
        title: "מחויבות לנגישות",
        content: [
          "אנו מחויבים להבטיח שהשירותים הדיגיטליים שלנו יהיו נגישים לכלל האנשים, כולל אנשים עם מוגבלויות.",
          "השקענו מאמצים רבים בכדי להבטיח שהאתר יעמוד בתקנים הבינלאומיים לנגישות אתרי אינטרנט, כגון תקן WCAG 2.1.",
        ],
      },
      {
        index: 2,
        title: "אמצעים שננקטו לשיפור הנגישות",
        content: [
          "ביצענו מספר התאמות באתר על מנת לשפר את הנגישות:",
          "- שימוש בצבעים בעלי ניגודיות גבוהה כדי להקל על קריאה.",
          "- התאמת גודל הגופן והיכולת להגדיל את הגופן.",
          "- ניווט באמצעות מקלדת לכל חלקי האתר.",
          "- שימוש בתיאורי תמונות כדי לסייע למשתמשים בעזרת קוראי מסך.",
        ],
      },
      {
        index: 3,
        title: "שימוש בטכנולוגיה תומכת",
        content: [
          "האתר תומך בטכנולוגיות עזר כמו קוראי מסך, תוכנות הגדלה וניווט מקלדת.",
          "כמו כן, ניתן להשתמש בקיצורי מקלדת לניווט מהיר באתר.",
        ],
      },
      {
        index: 4,
        title: "החרגות ותכנים שאינם נגישים",
        content: [
          "אנו ממשיכים לשפר את הנגישות של האתר, אך ייתכן כי חלק מהתכנים לא יהיו נגישים במלואם. אנו פועלים כדי לתקן כל חוסר תאימות בהקדם האפשרי.",
        ],
      },
      {
        index: 5,
        title: "פנייה בנוגע לנגישות",
        content: [
          `אם נתקלתם בבעיות נגישות או יש לכם הצעות לשיפור, נשמח לשמוע מכם. ניתן לפנות אלינו באמצעות הדוא"ל ${email_placeholder} או פשוט לחצו כאן ונעביר אתכם.`,
        ],
      },
    ],
  },
  english: {
    title: "Accessibility Statement",
    lastUpdated: `Last Updated: ${date_placeholder}`,
    sections: [
      {
        index: 1,
        title: "Commitment to Accessibility",
        content: [
          "We are committed to ensuring that our digital services are accessible to all people, including individuals with disabilities.",
          "We have made significant efforts to ensure that the website meets international accessibility standards such as WCAG 2.1.",
        ],
      },
      {
        index: 2,
        title: "Measures Taken to Improve Accessibility",
        content: [
          "We have made several adjustments on the website to improve accessibility:",
          "- Use of high-contrast colors to enhance readability.",
          "- Font size adjustments and the ability to enlarge text.",
          "- Keyboard navigation support for all parts of the website.",
          "- Use of alt-text descriptions to assist users with screen readers.",
        ],
      },
      {
        index: 3,
        title: "Use of Assistive Technology",
        content: [
          "The website supports assistive technologies such as screen readers, magnification software, and keyboard navigation.",
          "Additionally, keyboard shortcuts can be used for quick navigation throughout the website.",
        ],
      },
      {
        index: 4,
        title: "Exclusions and Non-Accessible Content",
        content: [
          "We continue to improve the accessibility of the website, but some content may not be fully accessible. We are working to address any incompatibilities as quickly as possible.",
        ],
      },
      {
        index: 5,
        title: "Contact Regarding Accessibility",
        content: [
          `If you encounter any accessibility issues or have suggestions for improvement, we would be happy to hear from you. You can reach us via email at ${email_placeholder} or through our contact us page (click on this section) .`,
        ],
      },
    ],
  },
  russian: {
    title: "Заявление о доступности",
    lastUpdated: `Последнее обновление: ${date_placeholder}`,
    sections: [
      {
        index: 1,
        title: "Обязательство по обеспечению доступности",
        content: [
          "Мы привержены обеспечению доступности наших цифровых услуг для всех людей, включая лиц с ограниченными возможностями.",
          "Мы предприняли значительные усилия для того, чтобы наш сайт соответствовал международным стандартам доступности, таким как WCAG 2.1.",
        ],
      },
      {
        index: 2,
        title: "Меры по улучшению доступности",
        content: [
          "Мы внесли несколько изменений на сайте для улучшения доступности:",
          "- Использование высококонтрастных цветов для улучшения читабельности.",
          "- Возможность увеличения шрифта для улучшения восприятия текста.",
          "- Поддержка навигации с помощью клавиатуры по всем частям сайта.",
          "- Использование описаний alt-text для поддержки пользователей с экранными считывателями.",
        ],
      },
      {
        index: 3,
        title: "Использование вспомогательных технологий",
        content: [
          "Наш сайт поддерживает использование вспомогательных технологий, таких как экранные считыватели, программы для увеличения экрана и навигация с помощью клавиатуры.",
          "Кроме того, можно использовать клавиатурные сочетания для быстрой навигации по сайту.",
        ],
      },
      {
        index: 4,
        title: "Исключения и недоступный контент",
        content: [
          "Мы продолжаем улучшать доступность нашего сайта, но некоторые материалы могут быть пока недоступны. Мы работаем над устранением этих несовместимостей в максимально короткие сроки.",
        ],
      },
      {
        index: 5,
        title: "Контакты по вопросам доступности",
        content: [
          `Если вы столкнулись с проблемами доступности или у вас есть предложения по улучшению, будем рады вашим отзывам. Вы можете связаться с нами по электронной почте: ${email_placeholder} или через нашу страницу .`,
        ],
      },
    ],
  },
  arabic: {
    title: "بيان الوصول",
    lastUpdated: `تاريخ آخر تحديث: ${date_placeholder}`,
    sections: [
      {
        index: 1,
        title: "الالتزام بتوفير الوصول",
        content: [
          "نحن ملتزمون بتوفير خدماتنا الرقمية لجميع الأفراد، بما في ذلك ذوي الاحتياجات الخاصة.",
          "لقد بذلنا جهوداً كبيرة لضمان أن يتوافق موقعنا مع المعايير الدولية للوصول، مثل WCAG 2.1.",
        ],
      },
      {
        index: 2,
        title: "إجراءات تحسين الوصول",
        content: [
          "لقد أجرينا عدة تغييرات على الموقع لتحسين الوصول:",
          "- استخدام ألوان عالية التباين لتحسين القراءة.",
          "- إمكانية تكبير الخط لتحسين قراءة النص.",
          "- دعم التنقل باستخدام لوحة المفاتيح في جميع أجزاء الموقع.",
          "- استخدام أوصاف النص البديل لدعم المستخدمين مع قارئات الشاشة.",
        ],
      },
      {
        index: 3,
        title: "استخدام التقنيات المساعدة",
        content: [
          "يدعم موقعنا استخدام التقنيات المساعدة، مثل قارئات الشاشة، وبرامج تكبير الشاشة، والتنقل باستخدام لوحة المفاتيح.",
          "بالإضافة إلى ذلك، يمكنك استخدام اختصارات لوحة المفاتيح للتنقل بسرعة عبر الموقع.",
        ],
      },
      {
        index: 4,
        title: "استثناءات ومحتوى غير متاح",
        content: [
          "نواصل تحسين إمكانية الوصول إلى موقعنا، ولكن قد تكون بعض المواد غير متاحة بعد. نحن نعمل على معالجة هذه التوافقات في أسرع وقت ممكن.",
        ],
      },
      {
        index: 5,
        title: "جهات الاتصال الخاصة بقضايا الوصول",
        content: [
          `إذا واجهت مشاكل في الوصول أو كان لديك اقتراحات لتحسين، سنكون سعداء بتعليقاتك. يمكنك الاتصال بنا عبر البريد الإلكتروني: ${email_placeholder} أو من خلال صفحتنا .`,
        ],
      },
    ],
  },
};

export default accessibilityStatementData;
