import React, { useCallback, useState } from "react";
import "./Segmented.css"; // Import the CSS file for styling

interface SegmentedProps {
  options: { key: string; label: string }[];
  defaultKey?: string;
  onChange: ({ key, label }: { key: string; label: string }) => void;
  componentKey?: string;
}

const Segmented: React.FC<SegmentedProps> = ({ options, defaultKey, onChange, componentKey }) => {
  const [value, setValue] = useState(options.find((v) => v.key === defaultKey)?.key || options[0].key);
  const [uniqueClass] = useState(`segmented-${componentKey || Math.random().toString(36).slice(2, 9)}`);

  const handleChange = useCallback(
    (option: { key: string; label: string }) => {
      setValue(option.key);
      onChange(option);
    },
    [onChange]
  );

  return (
    <div className={`segmented-controls ${uniqueClass}`}>
      {options.map((option, index) => (
        <React.Fragment key={index}>
          <input
            id={`${option.key.toLowerCase()}-${index}`}
            name={option.key.toLowerCase()}
            type="radio"
            checked={option.key === value}
            onChange={() => handleChange(option)}
          />
          <label htmlFor={`${option.key.toLowerCase()}-${index}`}>{option.label}</label>
        </React.Fragment>
      ))}
      <style>
        {`
          .${uniqueClass}  input:checked ~ label:last-of-type::after {
            transform: translateX(${-100 * (options.length - 1 - options.findIndex((v) => v.key === value))}%);
          }
        `}
      </style>
    </div>
  );
};

export default Segmented;
