import { FC, useState, useEffect, useRef, ReactNode } from "react";
import { createPortal } from "react-dom";
import "./AlertMessage.css";
import { ErrorIcon, InfoIcon, SuccessIcon } from "../Icons";
import WarningIcon from "../Icons/MessagesIcons/WarningIcon";
import InfoBox, { InfoBoxType } from "../InfoBox/InfoBox";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import Flex from "../Flex/Flex";

type Props = {
  message: ReactNode | string | undefined | null;
  delay?: number | null;
  type?: InfoBoxType;
  position?: "center" | "top" | "bottom" | "top-right" | "bottom-right" | "top-left" | "bottom-left";
  cssClass?: string;
};

const AlertMessage: FC<Props> = ({ message, position = "top-right", delay = 3, type, cssClass }) => {
  const {
    user: { language },
  } = useCustomContext();
  const [isOpen, setIsOpen] = useState(!!message);
  let timerID = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (message) {
      setIsOpen(true);
      if (delay) {
        timerID.current = setTimeout(() => {
          setIsOpen(false);
        }, delay * 1000);
      }
    } else {
      setIsOpen(false);
    }

    return () => {
      if (timerID.current) {
        clearTimeout(timerID.current);
      }
    };
  }, [message, delay]);

  if (!isOpen) return null;

  const handleClick = () => {
    if (timerID.current) {
      clearTimeout(timerID.current);
    }
    setIsOpen(false);
  };

  const icon: { [key in InfoBoxType]: ReactNode } = {
    warning: <WarningIcon onClick={handleClick} size="1.5em" clickable />,
    error: <ErrorIcon onClick={handleClick} size="1.5em" clickable />,
    success: <SuccessIcon onClick={handleClick} size="1.5em" clickable />,
    info: <InfoIcon size="1.5em" onClick={handleClick} clickable />,
    gray: <div></div>,
  };

  return createPortal(
    <div className={`warning-modal ${position} ${cssClass}`} onClick={() => setIsOpen(false)}>
      <Flex>
        {icon[type || "info"]}
        <InfoBox content={message} type={type || "info"} language={language} />
      </Flex>
    </div>,
    document.body
  );
};

export default AlertMessage;
