import { FC } from "react";
import { SkeletonCard } from "../../../components/UI";

const DrawProductsSkeleton: FC<{ numOfElements?: number }> = ({ numOfElements = 15 }) => {
  const array = Array.from({ length: numOfElements }, (_, index) => index);
  return (
    <>
      {array.map((i) => (
        <SkeletonCard
          key={i}
          cssClass="product_card_wrapper_vvv"
          animatedElements={[
            { type: "rect", width: "10%", height: "5%", top: "2%", left: "10%" }, // for promo
            { type: "rect", width: "80%", height: "50%", top: "15%", left: "10%" }, // for img
            { type: "rect", width: "40%", height: "5%", top: "calc(50% + 20%)", left: "30%" }, // for name
            { type: "rect", width: "40%", height: "5%", top: "calc(70% + 10%)", left: "30%" }, // for price
          ]}
        />
      ))}
    </>
  );
};

export default DrawProductsSkeleton;
