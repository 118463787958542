import { Cart, StoreEn } from "../../../../types/types";
import VirtualizedList, { VirtualizedListProps } from "../../../VirtualizedList/VirtualizedList";
import CartItem, { ItemFeatureType } from "../../CartItem/CartItem";

// export const drawCartItems3 = (itemsKeys: string[], cart: Cart, store: StoreEn) => {
//   return itemsKeys.map((item_code: string, idx: number) => {
//     const data = cart[item_code];
//     return (
//       <CartItem
//         productProp={data.product}
//         amount={data.amount}
//         item_code={item_code}
//         key={item_code}
//         selectedStore={store}
//       />
//     );
//   });
// };

type DrawCartOptions = {
  featureName?: ItemFeatureType;
  itemSize?: VirtualizedListProps["itemSize"];
  height?: VirtualizedListProps["height"];
  onScroll?: VirtualizedListProps["onScroll"];
  scrollTop?: VirtualizedListProps["scrollTopProp"];
  visibleItems?: VirtualizedListProps["visibleItems"];
};

const drawCartItems = (itemsKeys: string[], cart: Cart, store: StoreEn, options?: DrawCartOptions) => {
  const itemSize = options?.itemSize || 100; // For example, each item is 100px tall

  return (
    <VirtualizedList
      height={options?.height} // Height of the visible container
      itemCount={itemsKeys.length}
      itemSize={itemSize}
      visibleItems={options?.visibleItems || 6}
      scrollTopProp={options?.scrollTop}
      onScroll={options?.onScroll}
    >
      {(index, style) => {
        const itemKey = itemsKeys[index];
        const data = cart[itemKey];

        return (
          <div style={style} key={itemKey + index}>
            <CartItem
              productProp={data.product}
              amount={data.amount}
              item_code={itemKey}
              selectedStore={store}
              featureName={options?.featureName}
            />
          </div>
        );
      }}
    </VirtualizedList>
  );
};

export default drawCartItems;
