import { useState } from "react";
import CategoriesMap from "../../../../assets/Data/categories";
import { Cart, Language, StoreEn } from "../../../../types/types";
import { PlusOrMinusIcon } from "../../../UI/Icons";
import "./SortingCartByCategories.css";
import { Typography } from "../../../UI";
import drawCartItems from "./drawCartItems";
import { ItemFeatureType } from "../../CartItem/CartItem";
import { VirtualizedListProps } from "../../../VirtualizedList/VirtualizedList";

export const SortCartByCategories = ({
  cart,
  store,
  language,
  featureName,
  favoriteStore,
  onScroll,
  scrollTop,
}: {
  cart: Cart;
  store: StoreEn;
  language: Language;
  featureName?: ItemFeatureType;
  favoriteStore?: StoreEn;
  onScroll?: VirtualizedListProps["onScroll"];
  scrollTop?: VirtualizedListProps["scrollTopProp"];
}) => {
  const [visibleCategories, setVisibleCategories] = useState<Record<string, boolean>>({});
  const itemsKeys = Object.keys(cart);
  const categories: string[] = [];

  itemsKeys.forEach((item) => {
    const category = cart[item].product[store]?.category;
    category && categories.push(category);
  });

  const uniqueSet = new Set(categories);
  const uniqueCategories = Array.from(uniqueSet);

  const toggleCategoryVisibility = (cat: string) => {
    setVisibleCategories((prevVisibleCategories: any) => ({
      ...prevVisibleCategories,
      [cat]: prevVisibleCategories[cat] === undefined ? false : !prevVisibleCategories[cat],
    }));
  };

  return (
    <>
      {uniqueCategories.map((cat) => {
        const items: string[] = [];
        const itemsFromFavoriteStore: string[] = [];

        itemsKeys.forEach((item) => {
          if (cart[item].product[store]?.category === cat) {
            items.push(item);
          }

          if (
            favoriteStore &&
            featureName === "comparison_cart" &&
            cart[item].product[favoriteStore]?.category === cat
          ) {
            itemsFromFavoriteStore.push(item);
          }
        });

        const isVisible = visibleCategories[cat] !== false; // Visible if true or undefined

        const itemsToUse = featureName === "comparison_cart" ? itemsFromFavoriteStore : items;

        return (
          <div key={cat}>
            <div className="sorting-cart-categories-header-wrapper" onClick={() => toggleCategoryVisibility(cat)}>
              <div className="sorting-cart-title">
                <Typography.Title level={5}>{CategoriesMap[cat].category[language]} </Typography.Title>
                <div>({items.length})</div>
              </div>
              <PlusOrMinusIcon sign={isVisible ? "minus" : "plus"} size="10px" clickable color="var(--primary-color)" />
            </div>
            <div
              style={{
                maxHeight: isVisible ? "1000px" : "0",
                overflow: "auto",
                transition: "max-height 0.5s ease-in-out",
              }}
            >
              {isVisible &&
                drawCartItems(itemsToUse, cart, store, {
                  visibleItems: itemsToUse.length,
                  featureName,
                  scrollTop,
                  onScroll,
                })}
            </div>
          </div>
        );
      })}
    </>
  );
};
