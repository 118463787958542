import { FC, ReactNode } from "react";
import "../page.css";
interface ISideBarPropsContent {
  children: ReactNode;
  isOpen?: boolean;
  FloatBtn?: ReactNode;
  width?: string | number;
  bordered?: boolean;
  shadow?: boolean;
}

const SideBar: FC<ISideBarPropsContent> = ({ children, isOpen, FloatBtn, width, bordered = false, shadow = true }) => {
  return (
    <>
      {FloatBtn && (
        <div
          style={{
            position: "fixed",
            top: "calc(var(--nav-height) + 30px)",
            right: isOpen ? width || "350px" : "0%",
            opacity: isOpen ? 0 : 1,
            zIndex: 11,
            borderBottomLeftRadius: "25px",
            borderTopLeftRadius: "25px",
            background: "var(--primary-color)",
            transition: "all 0.2s ease",
            boxShadow: "var(--box-shadow-secondary)",
          }}
        >
          {FloatBtn}
        </div>
      )}
      <aside
        style={{
          width: isOpen ? width : undefined,
          border: bordered ? "var(--border-color) 1px solid" : undefined,
          boxShadow: shadow ? "var(--box-shadow)" : undefined,
        }}
        className={
          isOpen === true || isOpen === undefined
            ? `${"ui_sidebar"} ${"ui_layout_sticky"}`
            : `${"ui_sidebar"} ${"ui_layout_sidebar_closed"}`
        }
      >
        {isOpen ? children : null}
      </aside>
    </>
  );
};

export default SideBar;
