import { useEffect, useRef, useState } from "react";
import "./LegalPolicy.css";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import { Language } from "../../types/types";
import { Link } from "react-router-dom";
import privacyPolicyDataV1 from "./PrivacyPolicy/v1";
import termsOfUseDataV1 from "./TermsOfUsePolicy/v1";
import accessibilityStatementData from "./AccessibilitPolicy/v1";

export type LegalTerms = {
  [lang in Language]: {
    title: string;
    lastUpdated: string;
    sections: { index: number; title: string; content: string[] | string }[];
  };
};

interface ILegalPolicyProps {
  source: "privacyPolicy" | "termsOfUse" | "accessibility";
  skipTitle?: boolean;
}

const LegalPolicy = ({ source, skipTitle }: ILegalPolicyProps) => {
  const {
    user: { language },
  } = useCustomContext();

  const [terms, setTerms] = useState<LegalTerms[Language]>(privacyPolicyDataV1[language]);
  const [contactUsIdx, setContactUsIdx] = useState<8 | 11 | 5>(8);
  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    switch (source) {
      case "privacyPolicy":
        setTerms(privacyPolicyDataV1[language]);
        setContactUsIdx(8);
        break;
      case "termsOfUse":
        setTerms(termsOfUseDataV1[language]);
        setContactUsIdx(11);
        break;
      case "accessibility":
        setTerms(accessibilityStatementData[language]);
        setContactUsIdx(5);
        break;

      default:
        break;
    }

    topRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [source, language]);

  const drawSectionContent = (content: string | string[]) => {
    if (typeof content === "string") return <p className="legal_section-content">{content}</p>;

    return content.map((text) => {
      return (
        <p key={text} className="legal_section-content">
          {text}
        </p>
      );
    });
  };
  return (
    <div ref={topRef} className="legal_terms-container scrollable">
      {!skipTitle && <h1 className="legal_terms-title">{terms.title}</h1>}
      <p className="legal_terms-updated">{terms.lastUpdated}</p>
      {terms.sections.map((section, index) => (
        <div key={index} className="legal_terms-section">
          {section.index === contactUsIdx ? (
            <Link to="/contact">
              <h2 className="legal_section-title">{section.title}</h2>
              {drawSectionContent(section.content)}
            </Link>
          ) : (
            <>
              <h2 className="legal_section-title">{section.title}</h2>
              {drawSectionContent(section.content)}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default LegalPolicy;
