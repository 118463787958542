import { CSSProperties, FC, ReactNode } from "react";
import "./Drawer.css"; // Assume you have appropriate CSS for the animations and styles
import Typography from "../Typography/Typography";

type PlacementType = "left" | "right" | "top" | "bottom";

interface IDrawerProps {
  placement: PlacementType;
  closable?: boolean;
  onClose: () => void;
  isOpen: boolean;
  children: ReactNode;
  title?: string | ReactNode;
  footer?: string | ReactNode;
  getContainer?: boolean;
  width?: string;
  customHeader?: ReactNode;
  height?: string;
  headerStyle?: CSSProperties;
  style?: CSSProperties;
}

const Drawer: FC<IDrawerProps> = ({
  closable = true,
  getContainer = false,
  title,
  placement = "left",
  isOpen,
  onClose,
  footer,
  width = "fit-content",
  height = "fit-content",
  children,
  customHeader,
  headerStyle,
  style,
}) => {
  const handleMaskClick = () => {
    if (closable) {
      onClose();
    }
  };

  const drawerStyle: CSSProperties = {
    width: placement === "left" || placement === "right" ? width : "100%",
    height: placement === "top" || placement === "bottom" ? height : "100%",
    transform: isOpen
      ? "translate(0, 0)"
      : `translate(${placement === "right" ? "100%" : placement === "left" ? "-100%" : "0"}, ${
          placement === "bottom" ? "100%" : placement === "top" ? "-100%" : "0"
        })`,
    position: getContainer ? "absolute" : "fixed",
    [placement as PlacementType]: 0,
    ...style,
  };

  return (
    <>
      {isOpen && (
        <div
          className={`ui-drawer-mask ${getContainer ? "ui-drawer-mask-absolute" : ""}`}
          onClick={handleMaskClick}
        ></div>
      )}
      <div className={`ui-drawer ui-drawer-${placement}`} style={drawerStyle}>
        {customHeader ? (
          <div>{customHeader}</div>
        ) : (
          <div className="ui-drawer-header" style={headerStyle}>
            <Typography.Title level={5}>{title}</Typography.Title>
            {closable && (
              <button onClick={onClose} className="ui-drawer-close-btn">
                X
              </button>
            )}
          </div>
        )}
        <div className="ui-drawer-body">
          {/* Content goes here */}
          {isOpen && children}
        </div>
        {footer && <div className="ui-drawer-footer">{footer}</div>}
      </div>
    </>
  );
};

export default Drawer;
