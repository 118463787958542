import { useEffect, useState, FC, ReactNode, cloneElement, CSSProperties } from "react";
import "./SelectSearch.css";
import CheckMark from "../../Icons/CheckMark";
import { DataNotFound, SpinnerA } from "../../index";
import useSpeechToText from "../../../../hooks/useTextToSpeech";
import { SettingsIcon, MicrophoneIcon } from "../../Icons";
import { Language } from "../../../../types/types";
import TempComponentHebrewOnlyInfo from "../../../ErrorPage/HebrewOnlyInfo";

export type options = {
  label: string;
  value: string | number;
  data?: any;
};

type Props = {
  options?: options[];
  style?: CSSProperties;
  resetProps?: any[];
  placeholder?: string;
  handleSelected: (selectedData: any) => void;
  btn?: string | ReactNode;
  voice?: Language;
  Label?: FC<any>;
  getDebounceData?: { searchKey: string; body: any; getOptionsFromApi: () => Promise<options[]> };
  notFoundText?: string;
  // filters?: ReactNode;
  filters?: {
    component: JSX.Element;
    handleClose?: () => void;
  };
};

const SelectSearch: FC<Props> = ({
  options = [],
  resetProps = [],
  placeholder,
  handleSelected,
  btn,
  Label,
  voice,
  getDebounceData,
  notFoundText,
  filters,
  style,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [optionsState, setOptionsState] = useState(options);
  const [selectStatus, setSelectStatus] = useState({
    inputValue: "",
    selectedOption: "",
    isDropDown: false,
  });

  const { listening, startListening, transcription } = useSpeechToText();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSelectStatus((prev) => {
      return { ...prev, inputValue: value, isDropDown: true };
    });
  };

  useEffect(() => {
    setSelectStatus((prev) => {
      return { ...prev, inputValue: transcription };
    });
    // eslint-disable-next-line
  }, [listening]);

  const handleMarkSelectedOption = (option: { value: string | number }) => {
    setSelectStatus((prev) => {
      return { isDropDown: false, inputValue: prev.inputValue, selectedOption: option.value.toString() };
    });
  };

  const drawOptions = () => {
    if (!selectStatus.isDropDown) return [];

    return optionsState.map((o, idx) => {
      return (
        <div
          onClick={() => handleMarkSelectedOption(o)}
          className="result-option-wrapper "
          key={`${o.value}-${idx}`}
          onMouseDown={(e) => e.preventDefault()}
          style={{
            backgroundColor: selectStatus.selectedOption === o.value ? "var(--light)" : undefined,
          }}
        >
          {selectStatus.selectedOption === o.value ? (
            <span style={{ width: "2rem" }}>
              <CheckMark />
            </span>
          ) : (
            <span style={{ width: "2rem" }}></span>
          )}
          {Label ? Label!(o) : <span>{o.label}</span>}
        </div>
      );
    });
  };

  // Api Call
  const fetching = async () => {
    if (!getDebounceData) return;
    const { searchKey, body, getOptionsFromApi } = getDebounceData;

    body[searchKey] = selectStatus.inputValue;

    try {
      setIsLoading(true);

      const optionsFromApi = await getOptionsFromApi();

      setOptionsState(optionsFromApi);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //
  const getSelectedData = () => {
    return optionsState.find((i) => i.value === selectStatus.selectedOption);
  };

  // Prop Reset
  useEffect(() => {
    if (resetProps.length) {
      setSelectStatus({ inputValue: "", selectedOption: "", isDropDown: false });
      setOptionsState([]);
      // setOptionsState([]);
    }
    //eslint-disable-next-line
  }, resetProps);

  // Debounce Search

  useEffect(() => {
    if (getDebounceData) {
      const timerId = setTimeout(() => {
        getDebounceData &&
          selectStatus.inputValue?.length > 2 &&
          selectStatus.inputValue !== getSelectedData()?.label &&
          fetching();
      }, 500);

      return () => {
        clearTimeout(timerId);
      };
    }
    // eslint-disable-next-line
  }, [selectStatus.inputValue]);

  // Action made by User
  useEffect(() => {
    getSelectedData()?.data && handleSelected(getSelectedData()?.data);
    // eslint-disable-next-line
  }, [selectStatus.selectedOption]);

  useEffect(() => {
    if (isLoading) {
      setOptionsState([]);
    }
  }, [isLoading]);

  const handleCloseFilters = () => {
    if (!filters) return null;
    setIsFilterOpen((p) => !p);
    filters.handleClose && filters.handleClose();
  };

  const handleBlur = () => {
    if (!isFilterOpen) {
      setSelectStatus((prev) => ({
        ...prev,
        isDropDown: false,
        inputValue: prev.selectedOption ? (getSelectedData()?.label as string) : prev.inputValue || "",
      }));
    }

    // isFilterOpen && setIsFilterOpen(false);
  };

  const handleFocus = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectStatus((prev) => ({ ...prev, isDropDown: true }));
  };

  return (
    <div className="select-search-container" style={style}>
      <div
        className={`select-search-wrapper ${selectStatus.isDropDown ? "select-search-wrapper-focus" : ""} ${
          selectStatus.isDropDown && (selectStatus.inputValue.length > 2 || optionsState.length || isFilterOpen)
            ? "select-search-wrapper-focus-open"
            : ""
        }`}
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <div style={{ display: "flex", alignItems: "center", height: "100%", background: "white" }}>
          {voice && (
            <span onClick={() => startListening(voice)} className="select-search-btn">
              <MicrophoneIcon listening={listening} size={"1rem"} />
            </span>
          )}

          <input
            type="text"
            value={selectStatus.inputValue}
            onChange={handleSearchChange}
            className={`select-search-input ${btn ? "select-search-input-with-btn" : ""}`}
            placeholder={placeholder}
          />
          {btn && <span className="select-search-btn">{btn}</span>}
          {filters && (
            <span
              className={`select-search-btn responsive-hidden ${
                isFilterOpen && selectStatus.isDropDown ? "filters-bg-icon" : ""
              }`}
              onClick={handleCloseFilters}
            >
              {<SettingsIcon color="var(--primary-dark)" clickable size={"1.5rem"} />}
            </span>
          )}
        </div>
        <div className={selectStatus.isDropDown && !isLoading ? `select-result-div scrollable x1` : ""}>
          <div>
            {selectStatus.isDropDown &&
              !isLoading &&
              isFilterOpen &&
              filters?.component &&
              cloneElement(filters.component, {
                hardCloseFilters: () => {
                  setIsFilterOpen(false);
                  handleBlur();
                },
              })}
          </div>

          {drawOptions()}
        </div>
        {selectStatus.inputValue.length > 2 && selectStatus.isDropDown && (
          <div className="select-result-div">
            <TempComponentHebrewOnlyInfo type="message" source="SelectSearch" />
            {isLoading && <SpinnerA size={20} margin="15px" />}
            {!isLoading && optionsState.length === 0 && <DataNotFound text={notFoundText ? notFoundText : ""} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectSearch;
