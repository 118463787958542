import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import drawProducts from "../Shared/DrawProducts";
import fetchProductsV1 from "./fetch_products_v1";
import { Language, ProductDataType } from "../../../types/types";
import { Divider, SideBar } from "../../../components/UI";
import ScrollTopBtn, { scrollToTopRef } from "../../../components/UI/ScrollTopBtn/ScrollTopBtn";
import Cart from "../../../components/Cart/Cart";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import OnlineStoresMapper from "../../../hooks/useOnlineStores";
import DrawProductsSkeleton from "../Shared/DrawProductsSkeleton";

//#region utils
const drawDividerText = (language: Language, type: "auto" | "user" | "Allusers") => {
  const dict = {
    hebrew: {
      auto: "השוואה אוטומטית",
      user: "השוואת מוצרים שלי",
      Allusers: "התאמות נפוצות",
    },
    english: {
      auto: "Automatic Comparison",
      user: "My Product Comparisons",
      Allusers: "Common Matches",
    },
    russian: {
      auto: "Автоматическое сравнение",
      user: "Мои сравнения продуктов",
      Allusers: "Распространенные совпадения",
    },
    arabic: {
      auto: "المقارنة التلقائية",
      user: "مقارناتي للمنتجات",
      Allusers: "المطابقات الشائعة",
    },
  };
  return dict[language][type];
};

const pageSplit = [
  { type: "auto", key: "default" },
  { type: "user", key: "user" },
  { type: "Allusers", key: "allUsers" },
];

//#endregion utils

export interface productFromDb extends ProductDataType {
  item_code: string;
}

const ProductsV1 = () => {
  const location = useLocation();
  const {
    user: { language, isUser },
    onlineStores: { favoriteStore, otherStores },
    isCartOpen,
  } = useCustomContext();
  const [productsAuto, setProductsAuto] = useState<ProductDataType[]>([]);
  const [productsUser, setProductsUser] = useState<ProductDataType[]>([]);
  const [productsAllUser, setProductsAllUser] = useState<ProductDataType[]>([]);
  const [isLoading, setIsLoading] = useState({ auto: false, user: false, autoFinish: false });

  const category = `${location.pathname}`.replace("/products/", "");

  // observers
  const ref = useRef<HTMLDivElement>(null);
  const autoProductsRef = useRef<scrollToTopRef | null>(null);

  useLayoutEffect(() => {
    if (ref.current) ref.current.scrollTop = 0;
    if (autoProductsRef.current) autoProductsRef.current.scrollTop = 0;
  }, [location.pathname]);

  useEffect(() => {
    const body = {
      favoriteStore: OnlineStoresMapper.convertOnlineStoreToObj(favoriteStore),
      otherStores: OnlineStoresMapper.convertOnlineStoresToArrOfObj(otherStores),
      similarityScore: 0.3,
      category,
      joinByItemCode: true,
      // joinBySimilarity: false,
      isWeighted: false,
    };
    fetchProductsV1({ fetchSourceType: "auto", requestBody: body, setIsLoading, setProducts: setProductsAuto });
    if (autoProductsRef.current?.detected === true) {
      autoProductsRef.current.detected = false;
    }

    // eslint-disable-next-line
  }, [location.pathname]);

  const fetchUserFavProducts = async () => {
    if (isUser && !autoProductsRef.current?.detected) {
      const otherStoresArrOfObj = OnlineStoresMapper.convertOnlineStoresToArrOfObj(otherStores);

      await fetchProductsV1({
        fetchSourceType: "user",
        requestBody: {
          category,
          favoriteStore: OnlineStoresMapper.convertOnlineStoreToObj(favoriteStore),
          otherStores: otherStoresArrOfObj,
        },
        setIsLoading,
        setProducts: setProductsUser,
        setProductsExtra: setProductsAllUser,
      });
    }
  };

  return (
    <div ref={ref} style={{ display: "flex", width: "100%", height: "100vh", overflow: "auto", position: "relative" }}>
      <div
        id="products_v1_wrapper"
        className={isCartOpen ? "responsive-hidden" : ""}
        style={{ flex: 1, width: "100%", height: "100vh" }}
      >
        <div id="products_v1_auto">
          <Divider
            alignment={language === "hebrew" ? "right" : "left"}
            margin="16px 0"
            text={drawDividerText(language, pageSplit[0].type as "auto" | "user" | "Allusers")}
            isTitle
          />
          <div
            ref={autoProductsRef}
            className={`product-main-container`}
            style={{
              maxHeight: productsAuto.length ? "100vh" : "300px",
              overflow: "auto",
              position: "relative",
            }}
          >
            {isLoading.auto ? (
              <DrawProductsSkeleton numOfElements={15} />
            ) : (
              drawProducts(favoriteStore, productsAuto, 1)
            )}
          </div>
        </div>
        {isUser && (
          <div id="products_v2_user">
            <div>
              <Divider
                alignment={language === "hebrew" ? "right" : "left"}
                margin="16px 0"
                text={drawDividerText(language, pageSplit[1].type as "auto" | "user" | "Allusers")}
                isTitle
              />
              <div className="product-main-container" style={{ maxHeight: productsUser.length ? "100vh" : "300px" }}>
                {isLoading.user ? (
                  <DrawProductsSkeleton numOfElements={15} />
                ) : (
                  drawProducts(favoriteStore, productsUser, 1)
                )}
              </div>
              <Divider
                alignment={language === "hebrew" ? "right" : "left"}
                margin="16px 0"
                text={drawDividerText(language, pageSplit[2].type as "auto" | "user" | "Allusers")}
                isTitle
              />
              <div className="product-main-container" style={{ maxHeight: productsAllUser.length ? "100vh" : "300px" }}>
                {isLoading.user ? (
                  <DrawProductsSkeleton numOfElements={15} />
                ) : (
                  drawProducts(favoriteStore, productsAllUser, 1)
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <ScrollTopBtn targetElementRef={ref} />
      <ScrollTopBtn
        targetElementRef={autoProductsRef}
        style={{ right: "55px", background: "var(--secondary-color)" }}
        onEndView={fetchUserFavProducts}
      />
      <SideBar isOpen={isCartOpen}>
        <Cart />
      </SideBar>
    </div>
  );
};

export default ProductsV1;
