import { Outlet, useLocation } from "react-router-dom";
import NavBar from "./components/Navbar/NavBar";
import { directionByLanguage } from "./utils/utils";
import "../src/components/UI/index.css";
import { Content, Layout } from "./components/UI";
import MobileNav from "./components/Navbar/MobileNav/MobileNav";
import { screenSize } from "./utils/constants";
import HomePageFooter from "./components/Footer/HomepageFooter/HomePageFooter";
import useCustomContext from "./hooks/useCustomContext/useCustomContext";
import Accessability from "./components/Accessability/Accessability";
import { useState } from "react";

const App = () => {
  const {
    user: { language },
    theme,
  } = useCustomContext();

  const location = useLocation();
  const [isAccessabilityOpen, setIsAccessabilityOpen] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Check for Ctrl + U
    if (event.ctrlKey && event.key === "u") {
      setIsAccessabilityOpen((p) => !p);
      event.preventDefault();
    }
  };

  return (
    <div tabIndex={0} onKeyDown={handleKeyDown}>
      <Layout
        cssClass={`${directionByLanguage(language)} ${theme.contrast} ${theme.fontSize} ${theme.showLinksUnderline} ${
          theme.showAnimation
        } ${theme.wordSpacing}`}
      >
        <NavBar language={language} />
        <Accessability language={language} isOpen={isAccessabilityOpen} setIsOpen={setIsAccessabilityOpen} />
        <Content style={{ background: location.pathname === "/" ? "var(--white)" : undefined }}>
          <Outlet />
        </Content>
        {window.innerWidth <= screenSize.sm && <MobileNav />}

        {!location.pathname.startsWith("/products/") && <HomePageFooter />}
      </Layout>
    </div>
  );
};

export default App;
