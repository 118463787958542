import { useReducer, useState } from "react";
import { AuthenticationFormReducer, AuthenticationInitialState } from "../AuthReducer/AuthReducer";
import InputUI from "../../UI/Input/Input";
import { dictAuth } from "../dictionary";
import { PasswordIcon } from "../../UI/Icons";
import PasswordStrengthMeter, { calculateStrength } from "../Register/PasswordStrengthMeter/PasswordStrengthMeter";
import serverAPI from "../../../api/server";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Language } from "../../../types/types";
import "./ResetPassword.css";
import { Button, InfoBox } from "../../UI";
import { passwordStrengthValue } from "../../../utils/constants";

type ResMessage = {
  type: "warning" | "error";
  color: "red" | "black";
  text: string;
};

const ResetPassword = () => {
  const { token, language } = useLoaderData() as { token: string; language: Language };
  const navigate = useNavigate();

  const [formState, dispatch] = useReducer(AuthenticationFormReducer, AuthenticationInitialState);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<ResMessage>({ type: "warning", color: "red", text: "" });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const handleValidation = () => {
    if (calculateStrength(formState.password) < passwordStrengthValue) {
      setResponseMessage({
        type: "warning",
        color: "red",
        text: dictAuth.passMeterWeak[language],
      });
      return false;
    } else if (formState.password !== formState.confirmPassword) {
      setResponseMessage({ type: "warning", color: "black", text: dictAuth.passwordNotMatch[language] });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const isValid = handleValidation();
    if (!isValid) return null;
    try {
      setLoading(true);
      const { password } = formState;

      const payload = {
        newPassword: password,
        token,
      };

      const res = await serverAPI("users/reset-password", payload);

      if (res.statusCode === 200) {
        setLoading(false);
        navigate(`/login/${res.data.token}`);
      } else {
        // setResponseMessage({ type: "error", color: "red", text: res.data.message });
        throw new Error(res.data);
      }
    } catch (error: any) {
      let errorMessage = error.message;
      if (errorMessage === "Error: Unauthorized") errorMessage = dictAuth.resetPasswordErrorMessage[language];

      setResponseMessage({ type: "error", color: "red", text: `${errorMessage}` });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-password-container">
      <h2>{dictAuth.forgetPasswordMeetingMessage[language]}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <InputUI
            id="password"
            name="SET_PASSWORD"
            htmlFor="password"
            required
            type="password"
            language={language}
            label={dictAuth.password[language as Language]}
            value={formState.password}
            onChange={handleInputChange}
            icon={<PasswordIcon size={"55%"} color="var(--primary)" />}
            textMessage={<PasswordStrengthMeter password={formState.password} />}
          />
        </div>
        <div className="form-group">
          <InputUI
            id="passwordConfirm"
            name="SET_CONFIRM_PASSWORD"
            required
            type="password"
            language={language}
            label={dictAuth.confirmPassword[language as Language]}
            value={formState.confirmPassword}
            onChange={handleInputChange}
            icon={<PasswordIcon size={"55%"} color="var(--primary)" />}
            textMessage={<div style={{ height: "12px" }}></div>}
          />
        </div>
        <div className="form-group">
          <Button htmlType="submit" loading={loading} disabled={!formState.password || !formState.confirmPassword}>
            {dictAuth.resetPasswordSubmitBtn[language]}
          </Button>
        </div>
      </form>
      {responseMessage.text && (
        <InfoBox
          cssClass="res-message-reset-password"
          content={responseMessage.text}
          type={responseMessage.type}
          language={language}
          fontColor={responseMessage.color}
        />
      )}
    </div>
  );
};

export default ResetPassword;
