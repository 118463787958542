import { CSSProperties, ReactNode } from "react";

interface IPropsFlex {
  justify?: CSSProperties["justifyContent"];
  align?: CSSProperties["alignItems"];
  gap?: number;
  flexDirection?: CSSProperties["flexDirection"];
  style?: CSSProperties;
  children: ReactNode;
  cssClass?: string;
  onClick?: () => void;
  tabIndex?: number;
}

const Flex = ({ tabIndex, onClick, cssClass, justify, align, gap, flexDirection, style, children }: IPropsFlex) => {
  return (
    <div
      tabIndex={tabIndex}
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: justify,
        alignItems: align,
        gap,
        flexDirection,
        ...style,
      }}
      className={cssClass}
    >
      {children}
    </div>
  );
};

export default Flex;
