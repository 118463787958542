import { Dictionary } from "../../types/types";
import { Card, Flex, Modal } from "../UI";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import serverAPI from "../../api/server";
import useLocalStorage, { localStorageKeys } from "../../hooks/useLocalStorage";
import AlertMessage from "../UI/AlertMessage/AlertMessage";

const dict: Dictionary = {
  title: {
    hebrew: "",
    english: "Language Support",
    arabic: "دعم اللغة",
    russian: "Поддержка языков",
  },
  contentA: {
    hebrew: "",
    arabic: "الميزة التالية متاحة حالياً باللغة العبرية فقط. نحن نعمل جاهدين لجعلها متاحة لجميع مستخدمينا.",
    english:
      "The following feature is currently available in Hebrew only. We’re working hard to make it accessible to all our users.",
    russian:
      "Следующая функция в настоящее время доступна только на иврите. Мы усердно работаем над тем, чтобы сделать ее доступной для всех наших пользователей.",
  },
  contentB: {
    arabic:
      "إذا كنت ترغب في رؤية هذه الميزة بلغتك في أقرب وقت ممكن، يرجى تزويدنا بالتعليقات - سيساعدنا ذلك على إعطائها الأولوية.",
    english:
      "If you’d like to see this feature in your language sooner, please provide us a feedback—it will help us prioritize it.",
    hebrew: "",
    russian:
      "Если вы хотите увидеть эту функцию на своем языке быстрее, пожалуйста, дайте нам обратную связь — это поможет нам расставить приоритеты.",
  },
  sendFeedBackBtn: {
    hebrew: "",
    arabic: "إرسال التعليقات",
    russian: "Отправить отзыв",
    english: "Send Feedback",
  },
};
/**
 * This is Temp component until we finish translate the entire FE.
 * return dynamic component modal/card/alertMessage explain to users that some of features are not supported yet other langs
 * @returns modal contain form with info message that we support hebrew only
 * */
const TempComponentHebrewOnlyInfo = ({
  source,
  type,
}: {
  source: string;
  type?: "infoBox" | "modal" | "alert" | "message";
}) => {
  const {
    user: { isUser, fullName, email, language, phoneNumber },
  } = useCustomContext();
  const [langSupportStorage, setLangSupportStorage] = useLocalStorage(localStorageKeys.LanguageSupport, {});
  const key = source + language;
  if (language === "hebrew" || langSupportStorage[key]) return null;

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setLangSupportStorage({ ...langSupportStorage, [key]: true });
  };

  const onFeedBack = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const subject = `Language Support missing - ${source}`;
    const message = `${language} is missing in ${source} component\nProvided at ${new Date().toDateString()}`;
    await serverAPI("users/contact", { fullName, subject, phone: phoneNumber, email, message });
    handleClose(e);
  };

  const message = (
    <div>
      <div>1. {dict.contentA[language]}</div>
      <div className="space-height-sm"></div>
      <div>2. {isUser && dict.contentB[language]}</div>
    </div>
  );

  if (type === "message") return <AlertMessage delay={6} message={message} position="center" type="gray" />;
  else if (type === "infoBox")
    return (
      <Card>
        <Flex flexDirection="column" gap={5} justify="center">
          {message}
          {isUser && (
            <div className="button btn-dashed" onClick={onFeedBack}>
              {dict.sendFeedBackBtn[language]}
            </div>
          )}
        </Flex>
      </Card>
    );
  else if (type === "modal")
    return (
      <Modal
        zIndex={1001}
        title={dict.title[language]}
        withFooter={isUser}
        okText={dict.sendFeedBackBtn[language]}
        cancelText="close"
        maskClosable
        onOk={onFeedBack}
        onCancel={handleClose}
        width={400}
        visible={!langSupportStorage[source]}
      >
        <form>{message}</form>
      </Modal>
    );

  alert(dict.contentA.english);
  return null;
};

export default TempComponentHebrewOnlyInfo;
