import { goToErrorPage } from "../../../components/ErrorPage/helpers/errorPageHelper";
import { logoutUser } from "../../../components/Navbar/SiteNav/SiteNavOptions/UserAuthSelect/UserAuthSelect";
import { SiteContextType } from "../../../hooks/useCustomContext/useCustomContext";
import { User } from "../../../types/types";
import { checkTokenExpIsValid, parseJwt } from "../../../utils/utils";
import { redirect } from "react-router-dom";

export const ProtectedResetPassword = async (req: any, contextValues: SiteContextType) => {
  const { user, setUser } = contextValues;
  const { token } = req.params;

  if (user.isUser) {
    await logoutUser(setUser);
  }

  const decoded = parseJwt<User>(token);
  const isValid = checkTokenExpIsValid(decoded);

  if (!isValid || !decoded) {
    const path = goToErrorPage({ message: "expiredAction", status: 401 });
    return redirect(path);
  }

  return { token, language: user.language };
};
