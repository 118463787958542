import { FC, Fragment, useMemo, useState } from "react";
import { Cart, CartItem, Language, StoreEn } from "../../../../types/types";
import { ImageComponent } from "../../../UI";
import "./CheckoutStoreCart.css";
import { dictStores } from "../../../../dictionary/dictStores";
import LoginToStoreModal from "./LoginToStoreModal/LoginToStoreModal";
import { getTranslatedCategory } from "../../../../utils/utils";
import PriceCalculator from "../../../../models/ItemPriceCalculator/PriceCalculator";

type Props = {
  cart: Cart;
  store: StoreEn;
  language: Language;
  stores: StoreEn[];
  hasClubCard: StoreEn[];
};

const colDictionary = {
  itemName: {
    english: "Product Name",
    hebrew: "שם המוצר",
    russian: "Название продукта",
    arabic: "اسم المنتج",
  },
  unit: {
    english: "Measured in",
    hebrew: "יחידת מידה",
    russian: "Измеряется в",
    arabic: "يتم قياسه بوحدة",
  },
  amount: {
    english: "Amount",
    hebrew: "כמות",
    russian: "Количество",
    arabic: "الكمية",
  },
  totalPrice: {
    english: "Price",
    hebrew: "מחיר",
    russian: "Цена",
    arabic: "السعر",
  },
};

const CheckoutStoreCart: FC<Props> = ({ cart, store, language, stores, hasClubCard }) => {
  const [openLoginToStoreModal, setOpenLoginToStoreModal] = useState(false);

  const translatedCategoryMap = useMemo(() => {
    return getTranslatedCategory(language);
  }, [language]);

  const sortedCart = Object.values(cart).sort((a, b) => {
    const subCategoryA = a.product[store].sub_category;
    const subCategoryB = b.product[store].sub_category;
    return subCategoryA.localeCompare(subCategoryB);
  });

  let currentCategory: any = null;

  const screenWidth = window.innerWidth;

  const getItemPrice = (store: StoreEn, item: CartItem) => {
    return PriceCalculator.getItemPriceToString(store, item, cart);
  };

  const getTotalPrice = (store: StoreEn) => {
    return PriceCalculator.getTotalCartCost(store, cart).toFixed(2);
  };

  return (
    <>
      <LoginToStoreModal
        store={store}
        language={language}
        isOpen={openLoginToStoreModal}
        setIsOpen={setOpenLoginToStoreModal}
        cart={cart}
      />

      <table className="checked_store_cart" onClick={() => setOpenLoginToStoreModal(true)}>
        <thead>
          <th colSpan={7}>
            <span>{dictStores[store][language]} : </span>
            <span>עלות עגלה כוללת - </span>
            <span>{getTotalPrice(store)}</span>
            <span>&nbsp;</span>
            <span>&#8362;</span>
          </th>
          <tr>
            <th>{colDictionary.itemName[language]}</th>
            <th></th>
            <th>{colDictionary.unit[language]}</th>
            <th>{colDictionary.amount[language]}</th>
            <th>{colDictionary.totalPrice[language]}</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedCart.map((cartItem, idx) => {
            const { img_url, retailer_product_id } = cartItem.product[store];
            const { item_name, unit_qty } = cartItem.product[store];

            const amount = cartItem.amount;
            const total_price = getItemPrice(store, cartItem);
            const subCategory = cartItem.product[store].sub_category;

            // Add a title for a new category
            const categoryTitle =
              subCategory !== currentCategory ? (
                <tr key={subCategory}>
                  <td className="category-row" colSpan={7}>
                    <div className="category-name-div">{translatedCategoryMap[subCategory]}</div>
                  </td>
                </tr>
              ) : null;

            // Update the current category for the next iteration
            currentCategory = subCategory;

            return (
              <Fragment key={`${store}-${retailer_product_id}-${idx}`}>
                {categoryTitle}
                <tr>
                  <td>{item_name}</td>
                  <td>
                    {
                      <ImageComponent
                        src={img_url}
                        width={screenWidth > 600 ? "55px" : "35px"}
                        height={screenWidth > 600 ? "55px" : "35px"}
                        alt={"item in checkout cart " + item_name}
                      />
                    }
                  </td>
                  <td>{unit_qty}</td>
                  <td>{amount}</td>
                  <td>{total_price}</td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default CheckoutStoreCart;
