export type PossibleErrorPageTypes =
  | "unknown"
  | "serverDown"
  | "unauthorized"
  | "membersOnly"
  | "expiredAction"
  | "expiredLogin";

export const goToErrorPage = ({
  message,
  status,
  useDom,
}: {
  useDom?: boolean;
  message?: PossibleErrorPageTypes;
  status?: string | number;
}) => {
  let path = "";
  if (message && status) {
    path = `/error?message=${message}&status=${status}`;
  } else if (message) {
    path = `/error?message=${message}`;
  } else if (status) {
    path = `/error?status=${status}`;
  } else {
    path = `/error`;
  }

  if (useDom) {
    window.location.href = path;
  }

  return path;
};
